@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Author-profile {
    @include desktop {
        position: sticky;
        inset-block-start: 220px;
    }

    &-authorprofile {
        margin-block-start: 48px;
        padding: 32px 24px;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
        align-self: stretch;
        border-radius: 16px;
        background: var(--Neutral-100, #F9F6F4);
        width: 397px;

        @include mobile {
            width: -webkit-fill-available;
        }
    }

    &-author-image {
        display: flex;
        width: 80px;
        height: 80px;
        border-radius: 100%;
    }

    &-author-heading {
        display: flex;
        margin: 0;
        margin-block-start: 8px;
        color: #000000;
        opacity: 1;
    }

    &-author-para {
        margin: 0;
        margin-block-start: 8px;
        text-align: start;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }
}

.Blog {
    @include desktop {
        margin-inline-start: 10px;
    }
}

.AuthorBlogPage {
    &-socialMediaLink {
        span {
            margin-inline-end: 24px;
            padding-block-start: 10px;
        }
    }

    &-AuthorBlogPageMain {
        height: 100px ;
    }

    &-BlogCategory {
        position: relative;

        .SearchField-SearchInnerWrapper {
            .Form {
                column-gap: 10px;
                width: 100%;
                // height: 48px;
                margin-block-start: 32px;

                .SearchField {
                    &-Input {
                        background: #FAFAFA;
                        border-bottom: unset;
                        border: 2px solid #B2B1B0;
                        width: 397px;
                        height: 48px;
                        padding-inline-end: 12px;
                        padding-inline-start: 44px;
                        border-radius: 4px;
                        gap: 8px;
                
                        @include mobile {
                            display: flex;
                            column-gap: 10px;
                            width: 100%;
                            height: 48px;
                        }
                    }  
                }
            }
        }
    }

    &-BlogPageheading {
        .heading {
            inset-block-start: 27px;
        }
    }
 
    &-Authorheading {
        h1 {
            p {
                font-size: 28px;
                font-weight: 700;
                line-height: 32px;
                text-align: start
            }
        }
    }

    &-Authorsubheading {
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        text-align: start;
    }

    &-Wrapper {
        @include desktop {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-template-rows: auto 1fr;
            grid-column-gap: 3rem;
        }
    }

    &-BlogCategoryNameAuthor {
        display: none;
    }

    &-List-ul {
        @include mobile {
            display: flex;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            border-bottom: 1px solid #DDDDDD;
            grid-column-gap: 25px;
            -webkit-column-gap: 25px;
            column-gap: 25px;
        }
    }

    &-BlogCategoryReadTime {
        display: list-item;

        @include mobile {
            display: list-item;
            font-size: 8px;
            padding: 6px;
            margin-block-start: 10px;
        }
    }

    &-categoryList {
        font-size: 20px;
        color: #959595;
        padding: 0.5rem 0;
        border-bottom: 1px solid #959595;
    }

    &-AuthorBlogCategoryPosts {
        inset-block-start: 42px;
    }

    &-SearchButton {
        border-color: transparent;
        // inset-inline-end: 399px;
        inset-block-end: 45px;
        width: 17px;


        &.Button_isHollow:not([dirtasabled]):hover {
            // height: 50px;
            border-color: transparent;
        }

        @include mobile {
            border-color: transparent;
            inset-inline-end: 0px;
            inset-block-end: 44px;
            inset-block-start: -46px;
            width: 17px;
        }
    }

    &-BlogCategoryPostList {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 2rem;
            grid-row-gap: 4rem;
            margin-block-start: 24px
        }

        @include mobile {
            display: grid;
            grid-gap: 23px;
        }
    }

    &-Pagination {
        display: flex;
        inset-block-end: 32px;

        .totalPages {
            margin-block-start: -12px;
            margin-block-end: auto;
            color: #606060;
            font-weight: 600;
            font-size: 14px;
            width: 93px;
        }
    }

    &-Separator {
        @include mobile {
            border-bottom: 1px solid #707070;
            opacity: 0.25;
            margin-block-start: -2px;
        }
    }

    &-BlogCategoryPostLinkReadMore {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.02em;
        text-align: center;
        color: #73A054;
        display: flex;
        text-decoration: underline;
        margin-block-start: 12px;
    }

    &-BlogCategoryPostLink {
        .Image_isPlaceholder {
            height: 212px;
            width: 383px;

            @include mobile {
                width: 100%;
                height: 190px;
            }
        }

        .Image_imageStatus_image_not_found {
            height: 212px;

            @include mobile {
                width: 100%;
                height: 190px;
            }
        }
    }

    
    &-BlogCategoryPage {
        @include mobile {
            display: flex;
        }
    }

    &-AuthorBlogPostNoCategoryPost {
        @include desktop {
            font-size: 14px;
        }
    }

    &-BlogCategoryPostPagination {
        margin-block-start: 40px;

        @include mobile {
            nav {
                .Pagination-content {
                    display: unset;
                }
            }
    
            .Pagination-ListItem {
                min-width: 20px;
            }
        }
    }

    &-BlogCategoryPost {
        display: grid;

        a {
            height: fit-content;
        }

        .Image-Image {
            // width: 100%;
            width: 413px;
            height: 212px;
            position: relative;
            object-fit: cover;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            @include mobile {
                width: 100%;
            }
        }

        @include mobile {
            box-shadow: 4px 5px 15px #0000000f;
        }
    }

    &-BlogCategoryPostName {
        font-size: 20px;
        font-weight: 600;
        margin-block-start: 17px;
        color: #000000;
        max-height: 72px;
        overflow: hidden;

        @include mobile {
            font-size: 16px;
            padding: 10px;
        }
    }

    &-BlogCategoryAuthor {
        display: flex;
    }

    &-BlogAuthorCategory {
        display: none;      
    }

    &-AuthorProfile {
        padding: 0;
        margin-block-start: 8px;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: start;
    }

    &-authordescription {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: start;
        margin-block-start: 8px;
        margin-block-end: 24px;
    }

    &-AuthorBlogPostCategoryDetails {
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: start;
        color: #1E1E18;
        display: flex;
        align-items: center;
        gap: 22px;

        @include mobile {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.02em;
            text-align: start;
            color: #1E1E18;
        }
    }

    &-BlogCategoryDate {
        @include mobile {
            font-size: 8px;
            padding: 6px;
            margin-block-start: 10px;
        }
    }

    &-AuthorBlogPost {
        display: flex;
        justify-content: space-between;
    }

    &-AuthorBlogPostCategory {
        margin-inline-end: 16px;  
        font-size: 12px; 
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #3E3D30;
        padding: 4px 16px;
        grid-gap: 8px;
        gap: 8px;
        background-color: #F9F6F4;
        border-radius: 100px;
        width: fit-content;


        @include mobile {
            font-size: 8px;
            padding: 6px;
            margin-block-start: 10px;
        }
    }

    &-BlogCategoryPostContent {
        padding-block-start: 18px;
        padding-inline-end: 16px;
        color: #000000;
        font-size: 14px;
    
        @include desktop {
            max-height: 103px;
            overflow-y: auto;
    
            /* Style for desktop scrollbar */

            &::-webkit-scrollbar {
                width: 5px; /* Adjust the width as needed */
            }
    
            &::-webkit-scrollbar-thumb {
                background-color: #73A054; /* Set the color for the scrollbar thumb */
                border-radius: 6px; /* Optional: Add border-radius for a rounded appearance */
            }
    
            &::-webkit-scrollbar-track {
                background-color: #f1f1f1; /* Set the color for the scrollbar track */
            }
        }

        @include mobile {
            font-size: 12px;
            line-height: 23px;
            padding: 18px 12px 30px 10px;
            max-height: 103px;
            overflow-y: auto;
            /* Reset scrollbar styles for responsive view */

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #73A054;
                border-radius: 6px;
            }

            &::-webkit-scrollbar-track {
                background-color: #f1f1f1;
            }
        }
    }
    
    &-pagination {
        @include mobile {
            margin-inline-start: -10px;
        }

        li * {
            font-size: 12px;
        }

        .PaginationLink {
            font-size: 12px;
        }
    }
}
