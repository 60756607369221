@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.RadioButtonIcon {
    &-Circle {
        fill: var(--color-black);
    }

    &:hover, &_isActive {
        .RadioButtonIcon-Circle {
            fill: var(--color-black);
        }

        fill: var(--color-black);
    }
}
