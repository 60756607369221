@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Image {
    &-Default {
        img {
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 100%;
            object-position: 50% 50%;
            object-fit: contain;
            color: transparent;
    
            &::after {
                content: '';
                position: absolute;
                z-index: -1;
                inset-block-start: 0;
                inset-inline-start: 0;
                width: 100%;
                height: 100%;
                background: var(--image-default-background);
            }
        }
    }
}
