@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.SearchOverlay {
    &-Results {
        @include desktop {
            width: 850px;
            border-top: 2px solid var(--primary-base-color);

            p {
                margin-inline-start: 20px;
            }
        }

        .Xsearch {
            &-Container {
                &-details {
                    &-block-content {
                        display: flex;
                        flex-wrap: wrap;
                        margin-block-start: 16px;
                        padding-block-end: 8px;
                        border-bottom: 1px solid;
                    }
    
                    &-block-heading {
                        letter-spacing: 0;
                        text-transform: capitalize;
                        font-weight: 500;
                        padding: 0 24px;

                        @include mobile {
                            font-size: 15px;
                            padding: 16px 0 0;
                        }
                    }
    
                    &-name {
                        letter-spacing: 0px;
                        color: #9B9B9B;
                        opacity: 1;
                        font-weight: 300;
    
                        @include desktop {
                            font-size: 16px;
                        }
                    }

                    &-tab {
                        padding: 16px 0 0;
    
                        &-block {
                            display: flex;
                            justify-content: space-between;
                        }
    
                        &-recent-items {
                            padding: 7px 16px;
                            background: #e8f6d0;
                            border-radius: 18px;
                            margin-block-end: 16px;
    
                            &:not(:last-of-type) {
                                margin-inline-end: 16px;
                            }
                        }
    
                        &-block-recent-items {
                            font-weight: normal;
                        }
    
                        &.search-categories:last-child {
                            border-bottom: unset;
                        }
                    }
    
                    &-count {
                        font-weight: normal;
                    }
    
                    &-block {
                        &.related-terms-block {
                            @include mobile {
                                padding: 0;
                            }
                        }
                    }
                }
    
                &-products {  
                    &-heading {   
                        @include mobile {
                            font-size: 15px;
                            font-weight: 500;
                            padding: 18px 0 0;
                        }
                    }
    
                    &-container {
                        display: flex;
                        flex-direction: column;
    
                        @include desktop {
                            padding: 16px 24px;
                            overflow-y: unset;
                            height: 100%;
                        }
    
                        @include mobile {
                            overflow-y: unset;
                            padding: 16px 0;
                        }
                    }
    
                    .ProductCard {
                        margin-block-end: 0;
    
                        &:last-child {
                            margin-block-end: 0;
                        }
                    }
    
                    .ProductCard-Figure {
                        height: 200px;
    
                        img {
                            display: inline;
                            object-fit: cover;
                            height: 200px;
                        }
                    }

                    @include mobile {
                        .SearchOverlayProduct {
                            &-Heading {
                                width: 80%;
                            }

                            &-Name {
                                font-weight: 500;
                                font-size: 13px;
                            }

                            &-OwnerName {
                                display: flex;

                                span {
                                    font-size: 11px;
                                    font-weight: 500;
                                }
                            }

                            &-Price {
                                font-size: 12px;
                                font-weight: 500;
                            }

                            &-Offers {
                                font-size: 11px;
                                margin-block-start: 3rem;
                            }
                        }

                        &-viewAll {        
                            p {
                                text-transform: uppercase;
                                font-size: 12px;
                                text-decoration: underline;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }
}
