.PrimaryContentCardDescription {   
    .PrimaryContentCardImage {
        border-radius: 8px 8px 0px 0px;
        background: #D1CECD;
        aspect-ratio: 2/1;
    }

    .PrimaryContentCardDescription {
        border-radius: 8px;
        background: #EEE;
        padding-block-end: 24px;

        @include mobile {
            height: 100% !important; /* stylelint-disable declaration-no-important */ 
        }
    }

    .PrimaryContentCardContent {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        padding: 26px 24px 6px 24px;
        color: #000000;
        margin-block-start: 0px;
        margin-block-end: 0px;
        background: #EEE;
        
        @include mobile {   
            padding: 18px 12px 6px 12px;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            height: 25% !important;
        }
    }

    .PrimaryContentCardText {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.32px;/* stylelint-disable-line number-max-precision */
        padding: 0px 24px 23px 24px;
        color: #807E7D;
        background: #EEE;

        p {
            font-size: 16px;

            @include mobile {
                font-size: 14px;
            }
        }

        @include mobile {
            padding: 0px 12px 0px 12px;
            height: 25% !important;
        }
    }

    .PrimaryContentCardButton {
        justify-content: center;
        align-items: center;
        gap: 16px;
        border-radius: 8px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.32px;/* stylelint-disable-line number-max-precision */
        text-transform: uppercase;
        color: #FFFFFF;
        border: none;
        display: flex;
        align-self: flex-start;
        
        .pagebuilder-button-primary {
            margin-inline: 24px;
            border-radius: 8px;
            margin-block-end: none;
            background-color: #73A054;
            border: #73A054;

            @include mobile {
                margin-inline-start: 16px;
                margin-inline-end: 0px;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 22px;
            }

            span {
                font-size: 16px;
                
                @include mobile {
                    font-size: 14px;
                }
            }
        }

        .pagebuilder-button-primary:not([disabled]):hover {
            color: #FFFFFF;
            border-radius: 8px;
            background-color: #73A054;
            border: #73A054;
        }
    }
}
