.Promotions {
    width: 100%;
    margin-block-start: 30px;
    
    @include mobile {
        margin-block-start: 20px;
    }

    .Promotion-Heading {
        max-width: var(--content-wrapper-width);
        margin-inline: auto;
        margin-block-end: 30px;
        display: flex;
        justify-content: space-between;
        padding-inline: 40px;

        @include mobile {
            display: flex;
            padding-inline: 20px;
        }

        .Heading {
            font-size: 30px;
            font-family: "Sora", sans-serif;
            font-weight: 600;
            color: #000000;
            width: 100%;
            border-bottom: 1px solid #e0e0e0;
            padding-block-end: 26px;

            @include mobile {
                font-size: 16pt;
            }
        }
        
        .Sort-Position {
            display: none;

            @include mobile {
                width: inherit;
            }

            .Sort {
                font-size: 14px;
                font-family: "Sora", sans-serif;
                color: #000000;

                @include mobile {
                    font-size: 12pt;
                }                
            }

            .Sort-Options {
                border: none;
                font-size: 14px;
                font-family: "Sora", sans-serif;
                color: #000000;

                @include mobile {
                    padding-inline-start: inherit;
                }

                &:focus {
                    outline: none;
                }

                option {
                    padding: 1rem;
                }
            }
        }
    }

    .Promotion-Grid {
        max-width: var(--content-wrapper-width);
        margin-inline: auto;
        padding: 0 40px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 40px 30px;
        margin-block-end: 80px;

        @include mobile {
            grid-template-columns: repeat(1,1fr);
            grid-gap: 20px;
            padding: 0 20px;
        }

        .Promo-Items {
            box-shadow: 4px 9px 39px #00000014;
            padding: 2.4rem;
            padding-block-end: 3rem;

            @include mobile {
                padding: 2.2rem;
            }

            .Promtion-Img {
                width: 100%;
            }

            .card-img {
                padding-block-end: 54%;

                @include mobile {
                    padding-block-end: 51%;
                }

                img {
                    position: absolute;
                    height: 100%;
                }
            }

            .Promo-Description {
                font-size: 14px;
                font-family: "Sora", sans-serif;
                color: #000000;
                text-align: center;
                margin: 19px 0;
                line-height: 2.2rem;

                @include mobile {
                    font-size: 13px;
                    text-align: center;
                    margin: 16px 0;
                }
            }

            .Promo-Description-Btn {
                text-align: center;

                .Shop-btn {
                    background-color: #739536;
                    color: #ffffff;
                    font-size: 14px;
                    font-family: "Sora", sans-serif;
                    font-weight: 600;
                    border: none;
                    padding: 1.6rem 3.8rem;
                    letter-spacing: 1px;

                    @include mobile {
                        font-size: 12px;
                        padding: 1.5rem 3.6rem;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
