.Seedsman-test-row {
    @include mobile {
        margin-block-start: 16px;
    }
    
    display: flex;
    margin-block-start: 59px;
    align-items: center;
    background-color: #F3F3F3;
    flex-direction: row !important;/* stylelint-disable declaration-no-important */
    border-radius: 8px;
    cursor: pointer;

    .promo-image {
        aspect-ratio: 1;
        margin-inline-start: 8px;
        margin-block: 8px;
    }

    .promo-heading {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-inline-end: 7px;

        p {
            a {
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                color: black;

                @include mobile {
                    font-size: 20px;
                }

                &:hover {
                    color: black;
                }
            }

            margin: 0 !important;
            text-align: center;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
        }
    }
}





