.Shipping_info {
    max-width: var(--content-wrapper-width);
    margin: auto;
    width: 100%;
    margin-block-start: 30px;
    padding: 0;

    .info_header {
        font-size: 30px;
        letter-spacing: 0px;
        color: #000000;
        text-transform: unset;
        margin-block-end: 13px;
        

        @include desktop {
            line-height: 41px;
        }

        @include mobile {
            font-size: 16px;
        }
    }
}
