@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable no-missing-end-of-source-newline */

:root {
    --menu-item-height: 20px;
    --menu-total-height: 84px;
}

@keyframes slideIn {
    0% {
        transform: translateX(-900px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100vw);
    }
}

.Menu {
    &-MenuWrapper {
        padding: 1.4rem;
        margin: 0 24px;
        max-width: var(--content-wrapper-width);
        min-height: 5rem;

        @include desktop {
            position: relative;
        }

        @include mobile {
            margin-inline: 16px 0;
        }
    }

    &-SubCategoriesWrapperModal {
        @include desktop {
            // display: none; /* Hidden by default */
            position: fixed; /* Stay in place */
            z-index: 1; /* Sit on top */
            inset-inline-start: 0;
            inset-block-start: 0;
            width: 100%; /* Full width */
            height: 100%; /* Full height */
            overflow: auto; /* Enable scroll if needed */
            background-color: rgb(0,0,0); /* Fallback color */
            background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
        }

        .Menu-ItemCaption {
            @include desktop {
                font-size: 16px;
            }

            @media (min-width:1281px) and (max-width: 1450px) {
                font-size: 14px;
                padding-block-end: 6px;
            }

            @media (min-width:811px) and (max-width: 1280px) {
                font-size: 12px;
            }

            @media (min-width:811px) and (max-width: 1000px) {
                font-size: 10px;
            }
        }

        //Hiding extra categories coming from insider

        // .ins-menu-header-c114 { 
        //     display: none;
        // }
    }

    &-SubItemWrapper>.Menu-Link {
        font-weight: 500;

        @include mobile {
            font-weight: normal;
        }
    }

    &-Link {
        font-weight: 300;

        @include desktop {
            cursor: pointer;
        }
    }

    &-Link._hasChild {
        .Menu-ItemCaption::after {
            @include desktop {
                content: url('src/util/images/Chevron-Downdown.svg');
                position: absolute;
                inset-block-start: 0;
                font-size: 27px;
                color: black;
                font-weight: 400;
            }
        }
    }
    

    &-SubCategoriesWrapper {
        border-top: 2px solid #739536;
        position: absolute;
        inset-block-start: 100%;
        background: white;
        width: 100%;
        box-shadow: 0px 3px 11px #0000000D;

        @include desktop {
            background-color: #fefefe;
            padding: 20px;
            border: 1px solid #888;
            max-width: 90%;
            // min-width: 90rem;
            min-height: 100%;
            width: fit-content;
            inset-inline-start: 0;
            inset-block-start: 0;
            border-radius: 0 16px 16px 0;
        }

        // @media (min-width:811px) and (max-width: 1500px) {
        //     inset-inline-start: 5%;
        // }

        &:not(.NoAnimation) {
            @include desktop {
                animation: slideIn .5s forwards;
            }
        }

        &.slideOutx {
            @include desktop {
                animation: slideOut .5s forwards;
            }
        }

        @media (min-width:811px) and (max-width: 1280px) {
            max-width: 95%;
        }

        .Menu-MainCategories {
            @include desktop {
                border-bottom: 1px solid #EEEEEE;
                width: 100%;
            }

            .Menu-Item {                            
                @include desktop {
                    padding-block-end: 0;
                }

                &:first-of-type {
                    @include desktop {
                        background: none;
                        padding: 6px 8px;
                        padding-inline-end: 8px;
                        padding-block-end: 0;
                    }
                }

                .Menu-Link {
                    &_isHovered {
                        .Menu-ItemCaption {
                            @include desktop {
                                border-bottom: 2px solid #000000;
                            }

                            span {
                                @include desktop {
                                    color: #000000;
                                }
                            }
                        }
                    }
                }

                .Menu-ItemCaption {
                    @include desktop {
                        padding-block-end: 1rem;
                        color: #807E7D;
                    }

                    span {
                        @include desktop {
                            color: #807E7D;
                        }
                    }
                }
            }

            .Menu-Link._hasChild {
                .Menu-ItemCaption::after {
                    @include desktop {
                        content: none;
                    }
                }
            }

            // .Menu-ItemList {
            //     padding-block-end: 1rem;
            // }
        }

        .CloseIcon {
            &Wrapper {
                @include desktop {
                    fill: black;
                    position: revert;
                    position: absolute;
                    inset-inline-end: 20px;
                    inset-block-start: 23px;
                    cursor: pointer;
                }
            }

            @include desktop {
                fill: black;
            }
        }
    }

    &-MainCategories {
        @include desktop {
            margin: 0 0;
            // width: 100%;
        }

        @include mobile {
            margin-block-end: 30px;
        }
    }

    &-ItemCaption_type_main {
        text-transform: capitalize;
        font-weight: 500;
        margin: 0;
    }

    &-ItemDots {
        height: 3px;
        width: 3px;
        background-color: #000000;
        border-radius: 50%;
        margin-block-start: 5px;
        margin-inline-start: 20px;

        @include mobile {
            margin-block-start: 0;
            margin-inline-start: 0;
        }
    }

    &-ItemDots:last-child {
        display: none;
    }

    &-Item {
        /* stylelint-disable-next-line declaration-no-important */
        margin-block-end: 0 !important;

        @include desktop {
            height: var(--menu-item-height);
            margin: unset;
        }

        @include mobile {
            margin-block-end: 0;
        }

        @include tablet {
            margin-inline-start: 1px;
        }

        .ChevronIcon_direction_bottom {
            transform: rotate(0deg);
        }

        .ChevronIcon_direction_top {
            transform: rotate(90deg);
        }

        &:not(:first-of-type) {
            @include desktop {
                margin-inline-start: 20px;
            }

            @include tablet {
                margin-inline-start: 10px;
            }
        }
    }

    &-ItemList {
        @include mobile {
            padding-inline: 0 36px;
        }

        @include desktop {
            gap: 0;
            align-items: center;
        }

        @include tablet {
            gap: 1rem;
        }

        .Menu-Item {
            @include desktop {
                min-height: 30px;
                min-width: 92px;
                padding: 6px 8px;
                text-align: center;
                height: auto;
                border-radius: 8px;
                white-space: nowrap;
            }

            &:first-of-type {
                @include desktop {
                    min-height: 30px;
                    min-width: 136px;    
                    background: #EEEEEE;
                    padding-inline-end: 30px;
                }
            }
        }
    }

    .Menu-SubMenu {
        margin-block-end: unset;
    }

    &-SubMenu_isVisible {
        @include mobile {
            background-color: #ffffff;
            color: #585858;
        }

        .Menu-ItemCaption {
            @include mobile {
                color: #585858;
                font-size: 14px;
                font-weight: normal;
            }
        }

        .SubItemWrapper {
            font-size: 14px;
            font-weight: unset;
        }
    }

    &-ItemCaption_isSecondLevel {
        @include mobile {
            color: #585858;
        }
    }

    &-ItemCaption {
        color: #000000;
        font-size: 14px;
        line-height: 19px;


        @include tablet {
            font-size: 12px;
        }

        @include mobile {
            padding-block: 18px;
            margin-inline: 0;
        }
    }

    &-ItemCaption:hover {
        color: #739536;
    }

    &-megaMenu {
        padding-inline-start: 40px;
        padding-inline-end: 40px;
        padding-block-end: 40px;
    }

    &-megaMenu-title {
        font-size: 16px;
        font-weight: 500;
    }

    &-megaMenu-content {
        display: grid;
        grid-gap: 45px;
        grid-template-columns: repeat(7, minmax(0, 1fr));
        margin-block-start: 20px;

        .Link {
            display: grid;
            max-width: 128px;
            text-align: center;
        }

        .Image-HeightFull {
            height: 40px;
            width: 40px;
            margin-inline-start: auto;
            margin-inline-end: auto;
        }

        svg {
            margin: auto;
        }

        a {
            color: #000000;
            padding-block-start: 10px;
        }
    }

    &-ProfileSection {
        background-color: #f8f8f8;
        padding: 20px 16px;

        .Profile {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 600;

            @include mobile {
                justify-content: flex-start;
                column-gap: 5px;

                span {
                    display: flex;
                    column-gap: 5px;
                }
            }
        }

        .LoginSection {
            font-size: 12px;
            color: #959595;
        }
    }

    &-SubCategories {
        @include desktop {
            padding-inline: 0;
        }

        .Menu-SubMenu {
            @include desktop {
                display: flex;
                padding-inline-start: 8px;
                min-height: 88vh;
                margin-block-end: 0;
            }
        } 

        .Menu-ItemList_type_subcategory {
            @include desktop {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                align-items: flex-start;
                width: fit-content;
                padding-inline-end: 32px;
                border-right: 1px solid #EEEEEE; 
                padding-block-start: 32px;
            }

            @media (min-width:811px) and (max-width: 1180px) {
                padding-inline-end: 0;
            }

            .Menu-ItemCaption {
                @include desktop {
                    margin-block-start: 0;
                    // padding-inline-end: 50px;
                    color: #807E7D;
                }

                @media (min-width:811px) and (max-width: 1180px) {
                    padding-inline-end: 2rem;
                }

                &::after {
                    @include desktop {
                        content: none;
                    }
                }
            }

            .Menu-SubItemWrapper {
                @include desktop {
                    width: 100%;
                    // white-space: nowrap;
                }

                &._hasChild::after {
                    @include desktop {
                        content: url('src/util/images/Chevron-Right-Grey.svg');
                        position: absolute;
                        inset-inline-end: 7px;
                        inset-block-start: 5px;
                    }
                }

                &.active {
                    &._hasChild::after {
                        @include desktop {
                            content: url('src/util/images/Chevron-Right.svg');
                            position: absolute;
                            inset-inline-end: 7px;
                            inset-block-start: 5px;
                        }
                    }

                    .Menu-ItemCaption {
                        @include desktop {
                            color: #000000;
                            font-weight: 700;
                        }
                    }
                }

                .Menu-ItemCaption {
                    @include desktop {
                        width: 230px;
                    }

                    @include small-tablets {
                        width: 150px;
                    }

                    @include medium-tablets {
                        width: 190px;
                    }
                }
            }
        }
    }

    &-thirdlevel-ItemList {
        @include desktop {
            padding: 32px 0 0 32px;
            display: grid;
            grid-template-columns: auto 240px;
            width: 100%;
        }

        @media (min-width:901px) and (max-width: 1700px) {
            grid-template-columns: 3fr 200px;
        }

        @media (min-width:811px) and (max-width: 900px) {
            grid-template-columns: 3fr 150px;
        }

        @media (min-width:811px) and (max-width: 1180px) {
            padding: 32px 0 0 1rem;
        }

        &._no-child {
            @include desktop {
                padding: 32px 0 0 20px;
            }
        }

        a {
            @include desktop {
                font-weight: 700;
            }

            @media (min-width:811px) and (max-width: 1450px) {
                font-size: 14px;
            }
        }

        .Menu-SubLevelDesktop {
            @include desktop {
                min-width: 30rem;
            }

            @media (min-width: 1081px) and (max-width: 1700px) {
                min-width: 20rem;
            }

            @media (min-width: 811px) and (max-width: 1080px) {
                min-width: 15rem;
            }

            .Menu-ItemList {
                @include desktop {
                    // display: grid;
                    grid-template-columns: auto auto auto;
                    // grid-gap: 2.5rem;
                    justify-content: space-between;
                    align-items: baseline;
                    display: flex;
                    flex-direction: column;
                    max-height: 132rem;
                    flex-wrap: wrap;
                    grid-column-gap: 0;
                }

                @media (min-width: 811px) and (max-width: 1000px) {
                    max-height: 109rem;
                }

                @media (min-width: 1001px) and (max-width: 1349px) {
                    max-height: 116rem;
                }

                @media (min-width: 1350px) and (max-width: 1449px) {
                    max-height: 125rem;
                }

                // @media (min-width: 1350px) and (max-width: 1449px) {
                //     max-height: 132rem;
                // }

                @include tablet {
                    grid-template-columns: auto auto;
                    grid-gap: 2rem;
                }

                @media (min-width:811px) and (max-width: 1349px) {
                    grid-gap: 0;
                }

                @include small-tablets {
                    grid-gap: 0;
                }

                .Menu-SubCategory.hasChild {
                    @include desktop {
                        padding-block-end: 2rem;
                    }
                }

                .Menu-SubCategory {
                    .Menu-ItemCaption {
                        @include desktop {
                            // max-width: 19rem;
                            max-width: 29rem;
                        }

                        @media (max-width: 1450px) and (min-width: 1350px) {
                            max-width: 19rem;
                        }

                        @media (min-width: 1451px) and (max-width: 1700px) {
                            max-width: 21.2rem;
                        }

                        // @media (min-width:811px) and (max-width: 1400px) {
                        //     max-width: 175px;
                        // }

                        @media (min-width:811px) and (max-width: 1349px) {
                            max-width: 13.3rem;
                        }
                    }
                }
            }

            @include desktop {
                padding-inline-end: 4rem;
            }

            @media (min-width:811px) and (max-width: 900px) {
                padding-inline-end: 2rem;
            }

            &.longlist {
                .Menu-ItemList {
                    @include desktop {
                        max-height: 146rem;
                    }
                }
            }
        }

        .CmsBlock-Wrapper {
            @include desktop {
                max-width: 240px;
            }
        }

        .Loader-Scale {
            @include desktop {
                inset-block-start: 20rem;
            }
        }
    }

    &-ForthLevel {
        a {
            @include desktop {
                font-weight: 400;
            }
        }

        &.Menu-SubLevelDesktop {
            .Menu-ItemList {
                @include desktop {
                    display: block;
                }
            }
        }
    }

    &-AllLink {
        @include desktop {
            width: 100%;
            background: #EEEEEE;
            height: 42px;
            padding: 8px 16px 8px 16px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            position: absolute;
            inset-block-end: 0;
            font-size: 14px;
            text-transform: capitalize;
        }

        a {
            color: #000000;

            @media (min-width: 1001px) and (max-width: 1280px) {
                font-size: 12px;
            }

            @media (min-width: 811px) and (max-width: 1000px) {
                font-size: 10px;
            }
        }
    }

    &-SubCategory {
        ._hasChild.Menu-Link {
            .Menu-ItemCaption::after {
                @include desktop {
                    content: none;
                }
            }
        }

        @include desktop {
            padding-block: 8px 0;
        }

        @media (min-width:811px) and (max-width: 1349px) {
            padding-block: 0;
        }
    }



    &-ItemList-container {
        margin-block-end: 8rem;
    }
}

.Mega-menu-Image-Block {    
    .pagebuilder-column-group {
        @include desktop {
            flex-direction: column;
            padding-block-end: 10rem;
        }

        @include mobile {
            padding: 25px 0 40px 0;
            gap: 1rem;
        }

        .megamenu-image {
            @include desktop {
                /* stylelint-disable-next-line declaration-no-important */
                width: 100% !important;
                max-width: 240px;
                margin-inline-start: auto;
            }

            @media (min-width:811px) and (max-width: 1349px) {
                max-width: 200px;
            }

            img {
                border-radius: 8px;
            }
        }

        p {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            padding-block-start: 5px;
            text-align: start;

            @include small-tablets {
                font-size: 10px;
            }

            span {
                font-size: 16px;
                font-weight: 500;
                color: #000000;
                padding-block-start: 5px;

                @include small-tablets {
                    font-size: 10px;
                }
            }
        }
    }
}

.MobileMenu {
    .Menu {
        &-MainCategories {
            .Menu-SubMenu {
                display: block;
                margin-block-end: 0;
                padding-inline-end: 8px;
            }
        }

        &-ItemList_type_subcategory {
            padding-block: 0;
        }

        &-ItemList {
            padding-inline-end: 0;
        }

        &-SubMenu {
            padding-inline-start: 0;

            .Menu-ItemCaption.Menu-ItemCaption_type_subcategory {
                font-size: 16px;
                font-weight: 500;
            }
        }

        &-SubTitle {
            font-size: 18px;
            font-weight: 700;
            padding-block: 12px 15px;
            color: #000;
        }

        &-MainCategoriesBackButton {
            position: fixed;
            inset-block-start: 21px;
            z-index: 9999;
        }
    }

    &.TopLevelCategory {
        .Menu-SubTitle {
            display: none;
        }
    }

    &-Container {
        @include mobile {
            position: absolute;
            z-index: 1;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.5);
            inset-block-start: 0;
        }
    }

    &-Hidden-Container {
        @include mobile {
            display: none;
        }
    }
}

//If Menu is Active 

body:has(.Menu-SubCategoriesWrapperModal) {
    @include desktop {
        overflow: hidden;
    }
}

html:has(.MobileMenu-visible) {
    @include mobile {
        overflow: hidden;
    }
    // .MobileMenu-visible {
    //     pointer-events: all;
    // }
}

/* stylelint-disable */

.Menu-SubCategoriesWrapperModal {

&::-webkit-scrollbar {
    width: 10px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* color of the track */
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px /* color of the thumb */
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555; /* color of the thumb on hover */
  }

  &::-moz-scrollbar-thumb {
    background: #888;
    border-radius: 8px /* color of the thumb */
  }

  &::-moz-scrollbar-thumb:hover {
    background: #555;
  }

}

/* CSS sepcific for FireFox */

@-moz-document url-prefix() {
    .Menu{
        &-thirdlevel-ItemList {   
            .Menu-SubLevelDesktop {
                &.shortlist-14{
                width:48vw
                }
                &.longlist{
                    width:50vw
                    }
                .Menu-ItemList {
                    @include desktop {
                        justify-content: unset;
                    }
                }
            }
         }
    }
}

/* CSS sepcific for Safari */
@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
        @media only screen and (min-width: 811px) {
            .Menu-thirdlevel-ItemList._has-thirdlevel {
                grid-template-columns: 3fr 1fr;
            }
            .Menu-SubCategoriesWrapper:has(._has-thirdlevel) {
                width: 82%;
            }
        }

        @media only screen and (min-width: 811px) and (max-width: 1500px) {
            .Menu-SubCategoriesWrapper:has(.longlist) {
                width: 90%;
            }
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
   
    /* Safari only override */
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    @media only screen and (min-width: 811px) {
            .Menu-thirdlevel-ItemList._has-thirdlevel {
                grid-template-columns: 3fr 1fr;
            }
            .Menu-SubCategoriesWrapper:has(._has-thirdlevel) {
                width: 82%;
            }
        }
    }

    @media only screen and (min-width: 811px) and (max-width: 1500px) {
        .Menu-SubCategoriesWrapper:has(.longlist) {
            width: 90%;
        }
    }
}