@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable number-max-precision */


.BlogCategoryTabListItem {
    --border-width: 1px;
    --text-shadow: none;

    padding-inline-start: 0;
    margin-block-end: 0;

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 0;

        &_isActive {
            button {
                color: #739536;
            }

            border-bottom: 3px solid #63A532;
        }

        button {
            &::after {
                content: none;
            }

            @include desktop {
                font-size: 20px;
                font-weight: 600;
            }

            @include mobile {
                min-height: 56px;
                align-items: flex-end;
            }
        }
    }

    &_isActive,
    &:hover {
        .BlogCategoryTabListItem-Button {
            font-weight: 600;
            color: #63A532;

            &.beginner-cultivation {
                color: #EBBB42;
            }

            &.advanced-cultivation {
                color: #E59471;
            }

            &.cannabis-strains {
                color: #F9F6F4;
            }

            &.medical-cbd {
                color: #82DF64;
            }

            &.recipes {
                color: #9BCED2;
            }

            &.cannabis-history {
                color: #66B6D7;
            }
        }

        @include mobile {
            border-bottom: 3px solid #63A532;
        }
    }

    &-Button {
        padding: 10px 0;
        min-width: 100%;
        min-height: 38px;
        text-align: start;
        color: #959595;
        text-shadow: var(--text-shadow);
        letter-spacing: .03ex;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include desktop {
            padding: 20px 0px;
            min-width: 295px;
        }

        @include mobile {
            min-height: 56px;
            align-items: flex-end;
            font-weight: 500;
        }

        .ChevronIcon {
            width: 14px;
            height: 14px;
            display: inline-block;
        }
    }
}
