@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.HomePage {
    .CmsPage {
        min-height: 100vh;

        &,
        &-Wrapper {
            margin-block-end: 0;

            .CarouselWidget-prefix {
                .CarouselWidget {
                    .CarouselWidget__inner {
                        overflow: hidden;
                    }
                }
            }
        }
    }

    .ProductCard {
        &-fixeContent {
            text-align: start;
        }
    }

    .ProductConfigPopup {
        @include desktop {
            position: absolute;
        }
    }

    .popup {
        @include desktop {
            position: absolute;
            inset-block-end: 0;
        }
    }

    .ProductPrice-SubPrice {
        display: none;
    }

    .ProductCard-Wrapper .ProductCard-Attributes .ProductConfigurableAttributes-SwatchList {
        min-height: 100px;
        max-height: 100px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 4px;
        }
    }

    .ProductCard-Wrapper .ProductCard-Title {
        min-height: 100px;
    }
}
