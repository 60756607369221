@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable*/

:root {
    --header-logo-width: 194px;
    --header-logo-height: 24px;

    --header-height: 80px;
    --header-nav-height: 60px;
    --header-total-height: calc(
        var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + env(safe-area-inset-top)
    );

    @include desktop {
        --header-top-menu-height: 32px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height) + var(--menu-total-height));
    }

    @include mobile {
        --header-top-menu-height: 16px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height) + var(--menu-total-height));
    }
}

.Header {
    border-block-end: unset;
    position: inherit;

    &-Wrapper {
        margin-block-end: 1rem;

        .OfflineNotice {
            position: initial;
            inset-block-start: 0px;
            max-width: unset;
        }

        @include mobile {
            box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
        }

        // &:has(.Header_name_search) {
        //     @include mobile {
        //         .Header-TopMenu {
        //             display: none;
        //         }
        //     }
        // }
    }

    @include mobile {
        font-size: 16px;
    }

    &_isCheckout {
        .Header {
            &-Nav {
                justify-content: space-between;
            }
            
            &-NavLink {
                @include desktop {
                    display: none;
                }
            }

            &-IconsWrapper {
                .Header-Whishlist {
                    @include mobile {
                        display: none;
                    }
                }
            }
        }
    }

    &-ContinueButton {
        color: var(--color-black);
        font-size: 14px;
        text-decoration: underline;
        font-weight: 600;
    }

    &Wrapper {
        box-shadow: 0px 3px 11px #0000000d;

            .Header-Nav {
                .Logo {
                    min-height: 58px;
                    min-width: 120px;

                    @include mobile {
                    min-height: 56px;
                    height: var(--header-logo-height);
                    padding-block-end: 35%;
                }

            }

        }
    }

    .MobileMenu {
        background: #fff;
        position: absolute;
        height: 100vh;
        width: 90%;
        overflow-y: auto;
        overscroll-behavior: none;
        z-index: 1002;
        inset-inline-end: 0;
        inset-block-start: 0;
        transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px,
            rgba(0, 0, 0, 0.12) 0px 6px 30px 5px;

        &-hide {
            visibility: hidden;
            inset-inline-start: -100%;
        }

        &-visible {
            visibility: visible;
            inset-inline-start: 0;

            .MobileMenu-logo-section {
                position: sticky;
                inset-block-start: 0;
                background-color: #ffffff;
                z-index: 1002;

                @include mobile {
                    border-bottom: 1px solid #dddddd;
                    margin-inline: 16px;
                    padding-inline: 0;
                }

                .logo {
                    max-width: 100%;
                    width: 160px;
                }
            }

            .Menu-ProfileSection {
                position: sticky;
                inset-block-start: 58px;
                background-color: #f8f8f8;
                z-index: 100;
            }

            .Image {
                height: 100%;
            }

            .Logo-Placeholder .Image {
                height: 39px;
            }

            .Menu-MainCategories {
                margin-block-end: 0rem;

                .ChevronIcon {
                    @include mobile {
                        fill: #807E7D;
                    }
                }
            }

            .Menu-MenuWrapper {
                padding: 14px 0 14px 0;
                // margin-block-start: 60px;
                @include mobile {
                    // border-bottom: 1px solid #dddddd;
                    margin-inline-end: 24px;
                }
            }

            .Menu-Item {
                min-height: 30px;
                margin: 0;
            }

            .Menu-ItemCaption {
                padding-block: 1.5rem;
                font-size: 14px;

                @include tablet {
                    font-size: 12px;
                }

                @include mobile {
                    font-size: 18px;
                    font-weight: 700;
                }
            }

            .Header-MenuIcons {
                padding-block: 1rem 3rem;

                .MenuIcon {
                    padding: 1rem 0;
                    height: 4.3rem;

                    .Header-TrackOrder a {
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
            }

            .topBar-content {
                padding-block-end: 4rem;

                @include mobile {
                    padding-block-end: 6rem;
                }
            }

            .topBar-content div {
                padding: 1rem 0;
                height: 4.3rem;
            }
        }

        &-logo-section {
            display: flex;
            justify-content: space-between;
            padding: 1rem 1.4rem;

            svg {
                fill: black;
                width: 3.5rem;
                height: 3.5rem;
            }
        }
        
        .Menu-Title {
            display: flex;
            align-items: center;
            font-weight: 600;
            padding-inline-start: 2rem;
        }

        &.TopLevelCategory {
            .Menu-Title {
                padding-inline-start: 0;
            }

            .Menu-MainCategoriesBackButton
            {
                display: none;
            }

            .Header-Switcher {
                @include mobile {
                    display: block;
                }
            }

            .Header-TopMenu {
                @include mobile {
                    display: unset;
                }
            }

            .MobileMenu-CustomerContainer {
                @include mobile {
                    display: block;
                }
            }

            .MobileMenu-visible.Menu-MenuWrapper {
                @include mobile {
                    border-bottom: 1px solid #dddddd;
                }
            }

            .Menu-MenuWrapper {
                @include mobile {
                    border-bottom: 1px solid #dddddd;
                }
            }
        }
    }

    &-LogoWrapper {
        @include desktop {
            // position: absolute;
            position: relative;
            height: 59px;
            min-width: 120px;
        }

        @include tablet {
            min-width: auto;
        }

        grid-column: 1 / 2;
        min-height: unset;
        opacity: 1;
        max-width: 100%;
        width: 160px;
        margin: 0;
        inset-inline-start: 1rem;

        @include mobile {
            height: unset;
            inset-inline-start: 0;
        }

        @include minimobile {
            img {
                width: 80%;
            }
        }
    }

    &-HeartIcon {
        cursor: pointer;
    }

    &-MyAccountContainer {
        display: flex;
        margin-inline-start: unset;
        padding-block-start: 0.5rem;

        @include mobile {
            padding-block-start: 0;
        }
    }

    &-MyAccount {
        @include desktop {
            height: 40px;
        }
    }

    &-MyAccountWrapper {
        cursor: pointer;
        display: flex;
        column-gap: 5px;
    }

    &-MyAccountTitle {
        margin-inline-start: 5px;
    }

    &-NavbarBorder {
        border-bottom: 1px solid var(--primary-divider-color);
        margin-block-start: 8px;
        max-width: initial;
    }

    &-Nav {
        min-height: 60px;
        @include desktop {
            margin-block-start: 10px;
            grid-template-columns: minmax(var(--header-logo-width), 1fr) minmax(auto, 366px) 4fr;
            padding: 0;
            display: flex;
            // margin-inline: 20px;
            // padding-inline: 0;

            .LanguageSwitcher .FieldSelect-Select,
            .StoreSwitcher .StoreSwitcher-Title {
                font-weight: 500;
            }
        }

        @include mobile {
            padding: 4rem 10px;
            justify-content: space-between;
        }

        .Header-SearchFieldCancelButton{
            display: none;
        }

        .SearchField.SearchField_isVisible.SearchField_isActive {
            @include mobile {
                display: none;
            }
        }
        
        .SearchField-Cancel {
            @include mobile {
                display: none;
            }
        }

        .SearchField-Input {
            @include mobile {
                min-height: 48px;
            }
        }
    }

    &-IconsWrapper {
        display: inline-flex;
        white-space: nowrap;
        gap: 2rem;
        align-items: center;
        margin-inline-start: 2rem;

        @include desktop {
            align-items: baseline;
            margin-inline-end: 20px;
        }

        @include mobile {
            gap: 1.2rem;
            align-items: baseline;
            justify-content: flex-end;
        }
    }

    &-Button_type_minicart {
        @include desktop {
            padding-block-start: 0.6rem;
        }

        @include mobile {
            width: auto;
        }

        margin-inline-start: unset;
    }

    &-SeedFinder {
        a {
            color: #000000;
            font-weight: 500;
            font-size: 13px;
        }
    }

    &-TrackOrder {
        display: flex;

        a {
            color: #000000;
            font-weight: 500;
            font-size: 13px;

            @include desktop {
                font-weight: 400;
            }
        }
    }

    &-MinicartItemCount {
        background-color: #4FAB2F;

        @include mobile {
            inset-block-start: -9px;
            inset-inline-end: -12px;
        }
    }

    &-MinicartItemCount.EmptyCart {
        background-color: transparent;
        min-width: 10px;
        height: 10px;
        inset-block-start: -2px;

        @include mobile {
            inset-block-start: 0px;
        }
    }

    &-TopMenu {
        display: unset;
        justify-content: unset;
        height: unset;
        max-width: unset;
        margin-inline-start: auto;
        margin: 0;
        background-color: #f8f8f8;
        padding: 0 0;

        .footer {
            display: none;
        }
    }

    &-MobileTopMenu {
        @include mobile {
            display: none;
        }
    }

    &-MenuButtonSideBar {
        margin-block-start: 10px;

        .Header-MenuButton {
            width: 35px;
            height: 35px;
        }

        .CloseIcon {
            fill: black;
            width: 35px;
            height: 35px;
        }

        svg {
            @include mobile {
                margin-block-end: 6px;
            }
        }
    }

    .topBar-content {
        display: flex;
        flex-direction: row;
        gap: 47px;

        @include tablet {
            gap: 25px;
        }

        margin: auto;
        max-width: var(--content-wrapper-width);
        justify-content: flex-end;
        align-items: center;

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            padding-inline: 16px 36px;
            grid-gap: 0;
        }

        .Hotline-Link {
            text-align: center;
            display: flex;

            a {
                color: #151515;
            }

            .contact-no {
                font-weight: 600;
            }
        }

        .Wholesale-Link {
            background-color: #e6d93b;
            padding: 10px 12px 9px 12px;

            a {
                color: #000000;
            }
        }

        .topBar-OrderHotline {
            display: grid;
        }

        a {
            color: #464646;
            font-weight: 400;
            font-size: 13px;

            @include mobile {
                color: #000000;
                font-size: 16px;
                display: inline-block;
                width: 100%;
            }

            @include tablet {
                font-size: 12px;
            }
        }

        div {
            @include mobile {
                padding-block: 18px;
                width: 100%;
                border-bottom: 1px solid #dddddd;
            }
        }

        div:last-child {
            @include mobile {
                background-color: #ffffff;
            }
        }
    }

    &-MenuIcons {
        padding-inline: 16px 36px;
        background-color: #f8f8f8;
        padding-block-end: 20px;

        .MenuIcon {
            padding-block: 18px;
            width: 100%;
            border-bottom: 1px solid #dddddd;
            display: flex;
            justify-content: space-between;

            select {
                text-align: end;
                padding-inline-end: 20px;
                font-weight: 600;
            }

            .FieldSelect-Select {
                padding: 0;
                background-color: #f8f8f8;
                font-size: 13px;
            }

            .title {
                font-size: 12px;
                font-weight: 600;
                display: flex;
                align-items: center;
            }

            .LanguageSwitcher {
                border-bottom: unset;
                margin: 0 0;
                font-weight: 500;
            }

            .StoreSwitcher {
                margin: 0 0;
                font-weight: 500;
            }

            .CurrencySwitcher {
                margin: 0 0;
                font-weight: 500;

                @include mobile {
                    width: auto;
                }

                .ChevronIcon {
                    inset-inline-end: 0;
                }
            }
        }
    }

    // &_name_search {
    //     .SearchField {
    //         &_isActive {
    //             @include mobile {
    //                 display: block;
    //                 width: 100%;
    //                 margin-block-start: 8px;
    //             }
    //         }
    //     }
    //     // @include mobile {
    //     //     .Header {
    //     //         &-LogoWrapper,
    //     //         &-IconsWrapper {
    //     //             display: none;
    //     //         }
    //     //     }
    //     // }
    // }

    .CurrencySwitcher {
        @include mobile {
            margin-inline: 0;
        }

        .FieldSelect{
            .ChevronIcon {
                @include mobile {
                    transform: rotateZ(360deg);
                    right: 0;
                    fill: #807E7D;
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    img.LanguageSwitcher-Icon {
        width: 18px;
        height: 18px;
        border-radius: 50%;
    }
    
    .topbar-cms-content {
        color: #000;

        @include mobile {
            display: none !important;
        }

        @include desktop {
            z-index: 1;
        }

        .d-flex {
            display: flex;
            font-size: 10px;
            padding: 0 2rem;

            @include tablet {
                font-size: 8px;
                margin-inline-start: 10px;
            }

            .w-60 {
                width: 60%;

                a {
                    color: #fff;
                    font-size: 10px;
                    font-weight: normal;
                }

                @include tablet {
                    width: 50%;
                }
            }

            .w-40 {
                width: 40%;
                margin-inline-start: 35px;

                a {
                    color: #fff;
                    font-size: 10px;
                    font-weight: normal;
                }

                @include tablet {
                    width: 50%;
                }
            }
        }
    }

    .Header_mobile_topBar {
        @include mobile {
            .d-flex {
                > div {
                    font-size: 12px;
                }

                a {
                    color: #fff;
                    font-size: 10px;
                    font-weight: normal;
                }
            }
        }
    }
    
    &-Button_type_back {
        .ChevronIcon {
            @include mobile {
                width: 20px;
                height: 20px;
                vertical-align: middle;
                inset-block-end: 3px;
            }
        }
    }

    .HeaderWrapper {
        min-height : 100%;

        @include desktop {
            width: 100%;
            max-width: unset; 
        }
    }

    &-Switcher {
        border-bottom: 1px solid #f4f4f4;
        display: grid;
        justify-content: center;
        align-items: center;
        padding: 0 0 25px;
        grid-template-columns: 1fr 1fr;
        grid-gap: 56px;

        @include mobile {
            display: none;
            padding:0 0 2rem 0;
            border-bottom: 1px solid #dddddd;
            margin-inline: 16px 24px;
            margin-block-start: 2rem;
        }
    }

    &-Welcome {
        display: none;
    }

    &-NavLink {
        @include desktop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 28px;
            background: #EEEEEE;
            max-width: 1200px;
            margin: 0 auto;
        }
    }

    &-NavLinkContainer {
        @include desktop {
            background: #EEEEEE;
            min-height: 25px;
        }
    }

    &-RightContainer {
        @include desktop {
            display: flex;
            align-items: center;
        }
    }

    &-RightChild {
        @include desktop {
            display: flex;
            padding-inline: 5px;
        }
    }

    &-WholeSale {
        @include desktop {
            background: #EBCF88;
            padding: 2px 6px 2px 6px;
            border-radius: 4px;
            height: 19px;
            font-weight: 500;
            font-size: 13px;
            color: #000000;
        }
    }

    &-LeftContainer {
        display: flex;
    }

    &-FirstName {
        @include desktop {
            display: flex;
        }
    }

    &-logout {
        @include desktop {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    #CurrencySwitcherList {
        @include desktop {
            background: #EEEEEE;
        }

        @include mobile {
            padding-inline: 0;
        }
    }

    #LanguageSwitcherList {
        @include desktop {
            background: #EEEEEE;
            height: 28px;
            padding-block-end: 5px;
        }
    }

    .LanguageSwitcherList {
        @include mobile {
            font-size: 16px;
        }
    }

    &-CurrencySwitcher {
        display: flex;

        .LanguageSwitcher {
            @include desktop {
                padding-inline: 5px;
            }
        }

        img.LanguageSwitcher-Icon {
            width: 18px;
            height: 18px;
            border-radius: 50%;
        }
    }

    &-WholeSaleContainer {
        @include desktop {
            padding-inline-end: 6px;
        }
    }

    .WordBreak {
        @include mobile {
            display: none;
        }
    }

    .SearchField-Cancel {
        display: none;
    }

    &_name_search {
        .SearchField_isActive {
            #search-field {
                @include mobile {
                    border: 2px solid #000000;
                    border-radius: 4px;
                    background: #EAF5E7;
                }
            }

            .SearchField-CloseIcon {
                @include mobile {
                    display: block;
                    inset-inline-end: 10px;
                }
            }
        }

        .Header-IconsWrapper {
            @include mobile {
                display: none;
            }
        }

        .Header-Nav {
            .SearchField-Cancel {
                @include mobile {
                    display: none;
                }
            }
        }

        .SearchField-Cancel {
            @include mobile {
                display: block;
                text-align: end;
                padding-right: 1.5rem;
                margin-top: 0rem;
                position: absolute;
                bottom: 10rem;
            }
            @include desktop {
                display: none;
            }
        }
    }

    .LanguageSwitcher {
        .Field-Wrapper.Field-Wrapper_type_select{
            @include mobile {
                width: 100%;
            }

            #LanguageSwitcherList {
                @include mobile {
                    text-align: left !important;
                    font-size: 16px;
                    padding-inline-start: 10px;
                }
            }

            .FieldSelect{
                .ChevronIcon {
                    @include mobile {
                        transform: rotateZ(360deg);
                        right: 0;
                        fill: #807E7D;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }

    .MobileMenu {
        &-CustomerContainer {
            @include mobile {
                padding-inline: 16px 0;
                margin-right: 24px;
                display: none;
            }
        }

        &-CustomerDetails {
            @include mobile {
                padding: 1rem 0;
                height: 4.3rem;
                width: 100%;
                font-size: 16px;
            }

            a {
                @include mobile {
                    font-size: 16px;
                    font-weight: 400;
                }
            }

            .ChevronIcon {
                 @include mobile {
                    position: absolute;
                    right: 0;
                    top: 3px;
                    fill: #807E7D;
                 }
            }
        }

        .FirstName {
            @include mobile {
                font-weight: 600;
            }
        }

        .WholeSale {
            @include mobile {
                background: #EBCF88;
                border-radius: 4px;
                padding: 8px 12px 8px 12px;
                height: auto;
            }

            a {
                @include mobile {
                    font-weight: 500;
                    color: #000000;
                }
            }
        }
    }

    &-CustomerServicePopup {
        @include mobile {
            position: fixed;
            top: 0;
            background: white;
            width: 100%;
            z-index: 9999;
            height: 100%;
        }

        .topBar-content {
            @include mobile {
                padding-block-start: 16px;
            }

            div {
                @include mobile {
                    border-bottom: none;
                }
            }
        }       

        .Header-MenuButton {
            svg {
                fill: #000000;
            }
        }
    }
    
    &-CustomerServiceTitle {
        @include mobile {
            padding-inline-start: 0;
        }
    }

    &-Button_isVisible {
        @include mobile {
            display: none;
        }
    }
}