.main-banner {
    .main-content {
        @include tablet {
            margin-inline-start: 12px;
            margin-inline-end: 12px;
        }
    
        margin-block-start: 20px;
    
        .pagebuilder-column-group {
            @include mobile {
                margin-inline-end: 12px;
                margin-inline-start: 12px;
                display: flex !important; /* stylelint-disable declaration-no-important */
            }
    
            gap: 2rem;
            display: grid !important;
            grid-template-columns: 1fr 1fr 1fr;
    
            .initial-column {
                .seedsman-header {
                    @include mobile {
                        margin: 0;
                        padding-block-end: 6px;
                        font-size: 28px;
                    }
    
                    margin-block-end: 5px;
                    font-size: 32px;
                }
    
                .seedsman-para {
                    margin: 0 !important;
                    font-size: 16px;
    
                    @include mobile {
                        margin: 0;
                        font-size: 16px;
                    }
                }
    
                .main-button {
                    @include mobile {
                        padding-inline-start: 17px !important;
                        padding-block-end: 16px;
                    }
        
                    padding-block-end: 32px;
                    border-radius: 2rem;
                    padding-inline-start: 32px;
        
                    .pagebuilder-button-primary {
                        border-radius: 1rem;
                        margin: 0;
                    }
                }
    
                @include tablet {
                    margin: 0;
                }
    
                @include mobile {
                    width: 389px !important;
                    height: 360px;
                }
    
                grid-row: 1 / span 2;
                grid-column: 1 / span 2;
                width: 100% !important;
                border-radius: 1rem;
            }
        }
    
        .pagebuilder-column.column {
            width: 100% !important;
            border-radius: 1rem;
    
            @include mobile {
                width: 366px !important;
                height: 216px;
            }
        }
    
        .sub-content-one {
            @include mobile {
                // height: 50vh;
            }
    
            height: 100%;
            height: 486px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            border-radius: 2rem;
        }
    
        .sub-maincontent {
            @include mobile {
                padding-inline-start: 17px;
                padding-block-end: 15px;
            }
    
            padding-block-end: 21px;
            padding-inline-start: 33px;
        }
    
        .column {
            gap: 1.5rem;
    
            .blockone,
            .blocktwo {
                @include mobile {
                    padding-inline-start: 12px;
                }
    
                display: flex;
                height: 248px;
                font-size: 15px;
                border-radius: 1rem;
                justify-content: flex-end;
                flex-direction: column;
                padding-inline-start: 33px;
                width: 100%;
    
                .header-brand {
                    @include mobile {
                        margin-block-end: 26px;
                        font-size: 18px;
                    }

                    font-size: 20px;
                    margin: 0;
                }
            }
        }
    }    
}
