@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';


.BlogPostPage {
    &-MainContent {
        display: flex;
        justify-content: space-between;

        @include mobile {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    // &-shortcutsSticky { 
    //     @include mobile {
    //         /* stylelint-disable-next-line declaration-no-important */
    //         position: sticky !important;
    //         inset-block-start: 127px;
    //         z-index: 17;
    //     }
               
    //     @media only screen and (max-width: 401px) {
    //         inset-block-start: 157px;
    //     }

    //     @media only screen and (min-width: 401px) and (max-width: 480px) {
    //         inset-block-start: 141px;
    //         // inset-block-start: 184px;
    //     }  
    // }

    &-Header-Wrapper {
        &:has(.ins-slider-container) {
            .BlogPostPage-shortcutsSticky {
                inset-block-start: 181px;
            }
        }
    }

    &-Main {
        position: sticky;
        inset-block-start: 211px;
    }

    &-AuthorBio {
        position: relative;
    }
   
    &-MainAuthorBioDetails {
        @include desktop {
            position: sticky;
            inset-block-start: 195px;
        }
    }

    &-Contents {
        width: 60%;

        @include mobile {
            width: 100%;
            padding-inline-end: 12px;
            padding-inline-start: 12px;
        }
    }

    &-AuthorBioImage {
        width: 80px;
        height: 80px;
        border-radius: 80px;

        img {
            width: 80px;
            height: 80px;
            border-radius: 100%;
        }
    }

    &-Images {
        img {
            position: relative;
            object-fit: cover;
            height: 590px;

            @include mobile {
                height: 320px;
            }
        }

        margin-block-start: 40px;
        margin-block-end: 56px;

        @include mobile {
            margin-block-start: 16px;
            /* stylelint-disable-next-line declaration-no-important */
            margin-block-end: 0px !important;
        }

        .Image_isPlaceholder {
            height: 500px;
            width: 1200px;

            @include mobile {
                // width: 343px;
                width: 100%;
                height: 190px;
            }
        }

        .Image_imageStatus_image_not_found {
            height: 212px;

            @include mobile {
                width: 343px;
                height: 190px;
            }
        }
    }

    &-Title {
        h1 {
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            padding: 32px 0px 32px 0px; 

            @include mobile {
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 48px;
                padding: 16px 12px 16px 12px; 
                padding: 16px 0px 16px 0px; 
                margin-inline: 12px;
            }

            @include tablet {
                margin-inline: 12px;
            }
        }
    }

    &-shortDescription {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        margin-block-end: 32px;

        @include mobile {
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px;
            padding: 0px 12px 16px 12px;
            margin-block-end: 0px;
            margin-inline: 12px;
        }

        @include tablet {
            margin-inline: 12px;
        }
    }

    &-AuthorshortDescription {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-block-start: 8px;
        margin-block-end: 24px;
    }

    &-ShortcutHeading {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-block-end: 32px;
        margin-block-start: 0px;
    }

    &-SocialShareIcon {
        display: flex;
        width: 40px;
        height: 40px;
        padding: 4px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 100px;
        background: #F9F6F4;
    }

    &-ShortcutContent {
        a {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; 
        }

        li {
            margin-block-end: 16px;
            text-decoration: underline;
        }

        li::before {
            visibility: hidden;
        }

        li:last-child {
            margin-block-end: 0px;
        }

        select {
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            margin-block-end: 32px;
            margin-block-start: 0px;
            background: #F9F6F4;
            border-top: 1px solid #000000;
            border-bottom: 1px solid #000000;
            width: 100%;
            appearance: auto;
            padding-inline-end: 12px;
            padding-inline-start: 12px;
        }

        .underline-option {
            text-decoration: underline;
        }

        @include mobile {
            a {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; 
            }
    
            li {
                margin-block-end: 16px;
                text-decoration: underline;
            }
    
            li::before {
                visibility: hidden;
            }
    
            li:last-child {
                margin-block-end: 0px;
            }
    
            select {
                color: #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                margin-block-end: 32px;
                margin-block-start: 0px;
                background: #F9F6F4;
                border-top: 1px solid #ffffff;
                border-bottom: 1px solid #ffffff;
                width: 100%;
                padding-inline-end: 12px;
                padding-inline-start: 12px;
            }

            select option {
                width: 100% ;
                height: 178px;
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                text-decoration: underline;
                background: #ffffff;
            }

            .underline-option {
                text-decoration: underline;
            }
        }
    }

    &-socialMediaLink {
        span {
            margin-inline-end: 24px;
        }
    }

    &-AuthorImage {
        width: 32px;
        height: 32px;
        border-radius: 32px;
    }

    &-blogPostAuthorDetails {
        display: flex;

        @include mobile {
            flex-direction: column;
        }
    }

    &-AuthorBioDetails {
        width: 310px;
        padding: 32px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        border-radius: 16px;
        background: #F9F6F4;

        @include mobile {
            width: 100%;
        }
    }

    &-AuthorBioDetailsss {
        @include mobile {
            position: sticky;
            inset-block-start: 0;
        }
    }

    &-ShortcutContainer {
        width: 310px;
        padding: 32px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        border-radius: 16px;
        background: #F9F6F4;

        @include mobile {
            width: 100%;
            background: #ffffff;
            padding: 0px;
        }
    }

    &-ShortcutsWrapper {
        padding-block-start: 56px;

        @include mobile {
            padding-block-start: 0px;
        }
    }

    &-authorName {
        a {
            color: #3E3D30;
            color: var(--Secondary-900, #0F0F0C);
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; 
            margin-block-start: 8px;
        }

        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.28px;/* stylelint-disable-line number-max-precision */
        text-decoration-line: underline;
        margin-block-start: 8px;

        @include mobile {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            text-decoration-line: underline;
        }
    }

    &-authorBioName {
        a {
            color: #3E3D30;
            color: var(--Secondary-900, #0F0F0C);
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; 
            margin-block-start: 8px;
        }

        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.28px;/* stylelint-disable-line number-max-precision */
        margin-block-start: 8px;

        @include mobile {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
        }
    }

    &-AuthorImageConatiner {
        padding: 0px 12px 0px 0px;
    }

    &-authorNameDetails {
        display: flex;
        margin-block: auto;
        align-items: center;
        padding: 0px 24px 0px 0px;

        @include mobile {
            font-size: 12px;
            padding-inline-end: 12px;
            padding-inline-start: 12px;
            margin-inline: 12px;
        }

        @include tablet {
            margin-inline: 12px;
        }
    }

    &-postCreatedDate {
        margin-block: auto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding-inline-end: 32px;

        @include mobile {
            font-size: 12px;
            padding-inline-end: 0px;
        }

        li:last-child {
            margin-block-end: unset;
        }

        li {
            &::before {
                padding-inline-end: 5px;
                inset-inline-start: unset;
                position: unset;
            }
        }
    }

    &-readTime {
        margin-block: auto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding-inline-end: 24px; 

        @include mobile {
            font-size: 14px;
            padding-inline-end: 0px; 
        }

        li:last-child {
            margin-block-end: unset;
        }

        li {
            &::before {
                padding-inline-end: 5px;
                inset-inline-start: unset;
                position: unset;
            }
        }    
    }

    &-SocialShareWrapper {
        padding-block: 48px;
        margin-block: 48px;
        border-block: 1px solid #000000;
    }

    &-SocialShare {
        display: flex;
        justify-content: space-evenly;
    }

    &-BlogCategoryPostCategory {
        display: flex;
        gap: 24px;
        padding: 16px 12px 24px 12px;

        @include mobile {
            margin-inline: 12px;
        }

        @include tablet {
            margin-inline: 12px;
        }

        svg {
            margin-block-start: 4px;
        }
    }

    &-BlogPostCategory {
        display: flex;
        gap: 10px;
        width: fit-content;

        @include mobile {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            flex-wrap: wrap;
            padding: 0px 12px 0px 12px;
            margin-inline: 12px;
        }

        @include tablet {
            margin-inline: 12px;
        }
    }

    &-catItemName {
        color: #3E3D30;
        display: flex;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        background-color: #F9F6F4;
        border-radius: 100px; 

        @include mobile {
            font-size: 14px;
        }
    }

    &-content {
        figcaption {
            text-align: center;
        }

        @include mobile {
            margin-inline: 12px;
        }

        @include tablet {
            margin-inline: 12px;
        }
        
        h2 {
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 48px;
            margin-block: 32px;

            @include mobile {
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: 39px;
                margin-block-start: 40px;
                margin-block-end: 16px;
            }
        }

        h3 {
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 42px;
            margin-block: 32px;
            color: #0F0F0C;

            @include mobile {
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
                margin-block-start: 40px;
                margin-block-end: 16px;
                color: #0F0F0C;
            }
        }

        h4 {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 45px;
            margin-block: 32px;
            color: #0F0F0C;

            @include mobile {
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
                margin-block-start: 40px;
                margin-block-end: 16px;
                color: #0F0F0C;
            }
        }


        a {
            color: #73A054;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 27px;
        }

        p {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 27px;
        }

        .yoast-table-of-contents {
            ul {
                li {
                    a {
                        margin-inline-start: 10px;
                        color: black;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 27px;

                        @include mobile {
                            font-weight: 600;
                            font-size: 18px;
                        }
                    }

                    ul {
                        li {
                            margin-inline-start: 20px;
                            margin-block-start: 10px;
                        }
                    }
                }

                li::before {
                    color: #739536;
                    margin-block-start: -2px;
                    padding-inline-end: 0px;
                }
            }
        }

        color: #0F0F0C;
        line-height: 24px;

        @include desktop {
            font-size: 14px;
        }

        @include mobile {
            font-size: 12px;
        }

        ul {
            li::before {
                color: #739536;
                margin-block-start: -2px;
                inset-inline-start: 0px;
                position: relative;
            }
        }


        ol {
            li::before {
                margin-block-start: -2px;
                inset-inline-start: 0px;
                position: relative;
            }
        }
     
        img {
            max-width: 100%;
            width: revert-layer;
            height: revert-layer;
        }

        .blogContent {
            line-height: 24px;
            font-size: 14px;

            @include mobile {
                font-size: 12px;
            }
        }

        .wp-block-image {
            img {
                width: unset;

                @include mobile {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .ProductListWidget-Page {
            grid-template-columns: repeat(2, 1fr);
            background-color: #F9F6F4;
            padding: 24px 55px 24px 55px;

            @include mobile {
                padding: 0px;
                // z-index: 0;
            }
        }

        .ProductCard-Links p {
            font-size: 16px;
            line-height: 24px;
            height: 48px;
            font-weight: 600;

            @include mobile {
                font-size: 16px;
                line-height: 24px;
                height: 48px;
                font-weight: 600;
            }
        }
    }
}

.ContentWrapper.BlogPostPage-Wrapper {
    @include mobile {
        padding-inline: 0px!important;/* stylelint-disable-line declaration-no-important */
        position: relative;
    }
}

.ContentWrapper.BlogPostPage-Wrapper .ProductCard-Seedslogo img {
    @include desktop {
        height: auto;
        width: 100%;
    }

    @include mobile {
        height: auto;
        width: 47px;
    }
}

.ProductCard-Wrapper .ProductCard-moreInfo {
    font-weight: 600;
}
