@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Tooltip {
    &-ToolTipButton {
        .tooltipButton {
            cursor: pointer;

            span {
                margin: auto;
                color: var(--pdp-light-grey-color);
                font-weight: bold;

                @include mobile {
                    font-size: 8px;
                }
            }
        }

        &:hover {
            .Tooltip {
                &-ToolTipContentWrapper {
                    display: block;
                    z-index: 2;

                    &::before {
                        content: '';
                        position: absolute;
                        height: 15px;
                        width: 15px;
                        transform: rotate(45deg);
                        background-color: #73A054;
                    }

                    &.top {
                        &::before {
                            inset-block-end: -5px;
                            inset-inline-end: 7.5rem;
                        }
                    }

                    &.bottom {
                        &::before {
                            inset-block-start: -5px;
                            inset-inline-end: 2rem;
                        }
                    }

                    &.right {
                        &::before {
                            inset-inline-start: -5px;
                            inset-block-start: 2rem;
                        }
                    }

                    &.left {
                        &::before {
                            inset-inline-end: -5px;
                            inset-block-start: 2rem;
                        }
                    }
                }

                &-ToolTipContent {
                    padding: 16px;
                    white-space: nowrap;
                    color: var(--color-white);
                    background-color: #73A054;
                }
            }
        }
    }

    &-ToolTipContentWrapper {
        display: none;
        position: absolute;
        border-radius: 8px;

        &.bottom {
            inset-block-start: 2rem;
            inset-block-end: -2rem;
        }

        &.top {
            inset-block-end: 4rem;
            inset-inline-start: -6.5rem;
        }

        &.left {
            inset-block-end: 2rem;
            inset-block-start: -2rem;

            @include mobile {
                inset-inline-end: 1.5rem;
                inset-block-start: -2.25rem;
            }
        }

        &.right {
            inset-inline-start: 2rem;
            inset-block-start: -2;

            @include mobile {
                inset-inline-end: 1.5rem;
                inset-block-start: -2.25rem;
            }
        }

        &:hover {
            display: block;
        }
    }
}
