.Ethos {
    max-width: 100%;

    .Ethos-Banner {
        max-width: var(--content-wrapper-width);
        margin-inline: auto;

        @include mobile {
            padding: 5% 5%;
        }

        @include desktop {
            margin-block-start: 30px;
        }

        .Banner {
            width: 100%;
            margin-block-end: 5%;

            @include mobile {
                margin-block-end: inherit;
            }
        }
    }

    .Banner-Description {
        max-width: var(--content-wrapper-width);
        padding: 5.3rem 0;
        margin-inline: auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        @include mobile {
            display: inherit;
            padding: 0rem 1.6rem;
        }

        .Description-Left {
            grid-column: 1/2;

            .Description-Heading {
                font-size: 30px;
                font-family: "Sora", sans-serif;
                color: #000000;
                font-weight: 600;
                line-height: 1.5;

                @include mobile {
                    font-size: 16px;
                    margin-block-end: 5%;
                    margin-block-start: 0;
                }
            }
        }

        .Description-Right {
            grid-column: 2/4;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .Description-One {
                font-size: 14px;
                font-family: "Sora", sans-serif;
                color: #000000;
                line-height: 1.5;

                @include mobile {
                    font-size: 12px;
                    margin-block-end: 5%;
                    line-height: 22px;
                }
            }
        }
    }

    .Growing {
        background-color: #f2faf7;

        .Community {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 50px;
            gap: 100px;
            max-width: 1024px;
            width: 100%;
            margin-inline: auto;
            padding: 70px 0;

            @include mobile {
                grid-template-columns: 100%;
                width: inherit;
                gap: 30px;
                margin-block-end: 5%;
                padding: 2.3rem 1.6rem;
            }

            .Growing-Image {
                .Growing-Img {
                    width: 100%;
                    height: 100%;
                }
            }

            .Growing-Description {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .Growing-Description-One {
                    font-size: 30px;
                    color: #000000;
                    font-family: "Sora", sans-serif;
                    line-height: 1.5;
                    font-weight: 600;
                    margin-block-end: 5%;

                    @include mobile {
                        margin-block-end: 5%;
                        font-size: 16px;
                    }
                }

                .Growing-Description-Two {
                    font-size: 14px;
                    font-family: "Sora", sans-serif;
                    color: #000000;
                    line-height: 1.5;
                    margin-block-end: 5%;

                    @include mobile {
                        font-size: 12px;
                        margin-block-end: 5%;
                        line-height: 22px;
                    }
                }
            }
        }
    }
}
