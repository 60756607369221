@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.LogoutPopup {
    .Popup {
        &-Content {
            padding: 30px;
            overflow-y: unset;

            @include mobile {
                position: absolute;
                inset-block-end: 0;
                inset-block-start: 68%;
                border-radius: 0;
                padding-inline: 20px;
                animation: slideUp .5s cubic-bezier(.075,.82,.165,1);
            }

            p {
                font-size: 20px;
                margin-block-end: 30px;
                font-weight: 500;

                @include mobile {
                    padding-inline: 20px;
                    font-size: 14px;
                }
            }
        }

        &-CloseBtn {
            inset-block-start: -68px;

            @include desktop {
                inset-inline-end: -37px;
            }

            .CloseIcon {
                width: 35px;
                height: 35px;
            }
        }
    }

    &-section {
        .remove-popup {
            &-Close_button {
                display: none;
                justify-content: flex-end;
                margin-block-start: -30px;
                z-index: 999;

                button {
                    cursor: pointer;
                    inset-block-start: -32px;
                    inset-inline-end: -25px;
                }
            }

            &-Inner_content {
                display: flex;
                flex-direction: column;
                align-items: center;
                row-gap: 4rem;
                padding: 4rem 0 3rem 0;

                .title {
                    text-align: center;
                    font-size: 25px;
                    max-width: 230px;
                    font-weight: 500;
                }
            }

            &-Buttons {
                display: flex;
                justify-content: space-between;
                gap: 13px;

                @include mobile {
                    padding-inline: 20px;
                }

                button {
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 1px;
                    background-color: var(--primary-base-color);
                    cursor: pointer;
                    padding: 16px 4rem;
                    text-transform: uppercase;

                    @include mobile {
                        padding: 14px 3rem;
                        font-size: 12px;
                    }

                    @include smallmobile {
                        padding-inline: 2rem;
                    }
                }

                button:nth-child(1) {
                    background: #ffffff 0% 0% no-repeat padding-box;
                    border: 1px solid #000000;
                }

                button:nth-child(2) {
                    color: #ffffff;
                }
            }
        }
    }
}
