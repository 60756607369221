@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.StoredPaymentMethod {
    &-Method_Empty {
        width: 100%;
        text-align: center;
        margin-block-start: 80px;

        h2 {
            font-size: 20px;
        }

        p {
            font-size: 14px;
        }
    }

    &-method_content {
        background-color: #f5f5f5;
        padding-inline: 30px;
        margin-block-start: 20px;

        table {
            width: 100%;

            tbody {
                padding-block: 20px;
                font-size: 14px;

                .table-Heading {
                    border-bottom: 1px solid #afafaf;
                }

                .Delete {
                    text-decoration: underline;
                }
            }
        }
    }

    &-mob-content {
        display: flex;
        flex-direction: column;
        background-color: #f5f5f5;
        row-gap: 27px;
        padding: 17px;
        margin-block-start: 30px;

        .mob-content-inner {
            display: flex;
            flex-direction: column;

            h4 {
                border-bottom: 1px solid #afafaf;
                font-size: 14px;
                font-weight: 500;
                padding-block-end: 12px;
            }

            span {
                padding-block-end: 10px;
                border-bottom: 1px solid #afafaf;
                font-size: 14px;
                padding-block: 12px;
            }
        }

        .Delete {
            text-decoration: underline;
        }
    }
}
