.About-Container {
    max-width: var(--content-wrapper-width);
    margin: auto;
    width: 100%;
    margin-block-start: 30px;

    @include desktop {
        margin-block-end: 60px;
    }

    @include mobile {
        padding: 0 20px;
    }

    .About-One {
        margin-block-end: 5%;

        .Section-One {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 30px;

            @include mobile {
                grid-template-columns: repeat(2, 1fr);
                gap: 23px;
            }

            .Inner-One {
                h1 {
                    font-size: 70px;
                    font-family: "Sora", sans-serif;
                    font-weight: 600;
                    color: #a6a6a5;
                    line-height: 72px;

                    @include mobile {
                        font-size: 32px;
                        line-height: 33px;
                    }

                    @media only screen and (min-width: 768px) {
                        font-size: 70px;
                        line-height: 72px;
                    }
                }

                .Content-One {
                    @include mobile {
                        margin-block-end: 1%;
                    }
                }
            }

            .Inner-two {
                .Img1 {
                    width: auto;
                    height: unset;

                    @include mobile {
                        width: 100%;
                    }
                }
            }

            .Inner-Three {
                display: grid;

                @include mobile {
                    grid-column: 1/3;
                    gap: 0px;
                }

                .Count {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);

                    .Count-Two {
                        margin-inline-start: 2rem;

                        @include mobile {
                            margin-inline-start: 1rem;
                        }
                    }

                    .Count-Three {
                        margin-block-start: 3rem;

                        @include mobile {
                            margin-block-start: 1rem;
                        }
                    }

                    .Count-Four {
                        margin-block-start: 3rem;
                        margin-inline-start: 2rem;

                        @include mobile {
                            margin-inline-start: 1rem;
                            margin-block-start: 1rem;
                        }
                    }

                    .Count-No {
                        font-size: 36px;
                        font-family: "Sora", sans-serif;
                        font-weight: 600;
                        color: #000000;
                        line-height: unset;
                        margin-block-end: 0;

                        @include mobile {
                            font-size: 34px;
                        }

                        .Count_Plus {
                            font-size: 20px;
                            color: #63a532;
                            position: absolute;
                        }
                    }

                    .Count-Lable {
                        font-size: 16px;
                        font-family: "Sora", sans-serif;
                        color: #000000;

                        @include mobile {
                            font-size: 14px;
                        }
                    }
                }

                .Count-Text {
                    .Content-One {
                        font-size: 24px;
                        font-family: "Sora", sans-serif;
                        font-weight: 600;
                        line-height: 39px;
                        color: #000000;
                        margin-block-start: 3rem;
                        margin-block-end: 3rem;

                        @include mobile {
                            font-size: 15px;
                            line-height: 26px;
                            margin-block-start: 0rem;
                            margin-block-end: 0rem;
                        }
                    }
                }
            }
        }
    }

    .About-Two {
        margin-block-end: 5%;

        @include mobile {
            margin-block-start: 5%;
        }

        .Section-Two {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;

            @include mobile {
                display: inherit;
            }

            .Story-Left {
                font-size: 25px;
                font-family: "Sora", sans-serif;
                font-weight: 600;
                color: #000000;

                .Story-Title {
                    h2 {
                        font-size: 30px;

                        @include mobile {
                            font-size: 15px;
                        }
                    }

                    @include mobile {
                        display: flex;
                        align-items: center;
                        margin-block-end: 5%;
                    }

                    .Circle {
                        margin-block-start: 5%;
                        width: 60%;

                        @include mobile {
                            margin-block-start: inherit;
                            width: inherit;
                            margin-inline-start: 10%;
                        }
                    }
                }
            }

            .Story-Right {
                grid-column: 2/4;
                font-size: 14px;
                font-family: "Sora", sans-serif;
                color: #000000;

                @include mobile {
                    font-size: 12px;
                }
            }

            p {
                line-height: 22px;
            }
        }
    }

    .About-Three {
        background-color: #f2faf7;
        margin-block-end: 80px;
        width: 100vw;
        position: relative;
        margin-inline-start: calc(-50vw + 49.3%);

        @include mobile {
            margin-inline-start: calc(55% - 50vw);
            margin-block-end: 30px;
            width: 100%;
        }

        .Section-Three {
            max-width: var(--content-wrapper-width);
            margin-inline: auto;
            background-color: #f2faf7;
            display: grid;
            grid-template-columns: 50% 50%;
            padding-inline-start: 12rem;

            @include mobile {
                grid-template-columns: inherit;
                padding: 0 16px;
                padding-inline-start: 1rem;
            }

            .Grid-Img3 {
                margin: 10%;
                margin-inline: 0;

                .Img3 {
                    width: auto;
                    height: unset;

                    @include mobile {
                        width: 100%;
                    }
                }
            }

            .Grid-Description {
                margin: 10%;
                background-color: #f2faf7;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-inline-start: 0;

                @include mobile {
                    margin: 0%;
                }

                .Description-Heading {
                    font-size: 30px;
                    font-family: "Sora", sans-serif;
                    font-weight: 600;
                    color: #000000;
                    background-color: #f2faf7;
                    line-height: 49px;
                    padding-inline-end: 100px;

                    @include mobile {
                        font-size: 14px;
                        line-height: 22px;
                        padding-inline-end: 0px;
                    }
                }

                .Description-One,
                .Description-Two,
                .Description-Three {
                    font-size: 14px;
                    font-family: "Sora", sans-serif;
                    color: #000000;
                    background-color: #f2faf7;
                    line-height: 22px;

                    @include mobile {
                        text-align: inherit;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .About-Four {
        margin-block-end: 5%;

        .Seedsman-Mission {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            @include mobile {
                grid-template-columns: inherit;
                grid-gap: inherit;
            }

            .Mission-One {
                .Mission-Heading {
                    font-size: 30px;
                    width: 52%;
                    font-family: "Sora", sans-serif;
                    font-weight: 600;
                    line-height: 49px;
                    color: #000000;
                    margin-block-end: 5%;
                    margin-block-start: 0;

                    @include mobile {
                        font-size: 16px;
                        margin-block-end: 5%;
                        text-align: start;
                        width: inherit;
                    }
                }

                p {
                    font-size: 14px;
                    font-family: "Sora", sans-serif;
                    line-height: 22px;
                    color: #000000;
                    width: 80%;
                    margin-block-end: 5%;

                    @include mobile {
                        font-size: 12px;
                        width: 100%;
                        margin-block-end: 4%;
                    }
                }
            }

            .Mission-Two {
                padding-inline-start: 6rem;

                @include mobile {
                    padding-inline-start: 0rem;
                }

                p {
                    font-size: 14px;
                    font-family: "Sora", sans-serif;
                    line-height: 22px;
                    color: #000000;
                    margin-block-end: 5%;
                    width: 78%;

                    @include mobile {
                        font-size: 12px;
                        width: 100%;
                        margin-block-end: 4%;
                    }
                }
            }

            .Mission-Three {
                .Img4 {
                    width: auto;
                    height: unset;

                    @include mobile {
                        width: 100%;
                    }
                }
            }

            .Mission-Four {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                padding-inline-start: 6rem;

                @include mobile {
                    margin-block-start: 5%;
                    padding-inline-start: 0rem;
                }

                p {
                    font-size: 14px;
                    font-family: "Sora", sans-serif;
                    line-height: 22px;
                    color: #000000;
                    margin-block-end: 5%;
                    width: 80%;

                    @include mobile {
                        width: 100%;
                        font-size: 12px;
                    }
                }

                .Img5 {
                    width: fit-content;
                    height: unset;

                    @include mobile {
                        width: 100%;
                    }
                }
            }
        }
    }

    .About-Five {
        margin-block-end: 60px;
        background-color: #f4f4f4;
        width: 100vw;
        position: relative;
        margin-inline-start: calc(-50vw + 49.3%);

        @include mobile {
            margin-block-end: 35px;
            inset-inline-start: 0;
            width: 100%;
        }

        .Our-Promises {
            text-align: center;
            font-size: 30px;
            font-family: "Sora", sans-serif;
            font-weight: 600;
            color: #000000;
            margin-block-end: 5%;
            inset-block-start: 5rem;

            @include mobile {
                font-size: 16px;
                text-align: start;
                inset-block-start: 2rem;
                inset-inline-start: 2rem;
            }
        }

        .Promises {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 50px;
            width: auto;
            margin: auto;
            max-width: var(--content-wrapper-width);
            padding: 0% 1%;

            @include mobile {
                grid-template-columns: repeat(5, 1fr);
                grid-template-rows: 1fr;
                grid-gap: 20px;
                padding: 6%;
                overflow-x: scroll;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .Promises-Container {
                display: grid;
                justify-items: center;

                @include mobile {
                    display: inherit;
                    justify-items: inherit;
                    width: 260px;
                    padding: 10% 6%;
                    background-color: #ffffff;
                }

                .Promises-Heading {
                    text-align: center;
                    font-size: 16px;
                    font-family: "Sora", sans-serif;
                    color: #000000;
                    margin-block-end: 3%;
                    margin-block-start: 3%;

                    @include mobile {
                        font-size: 12px;
                        text-align: start;
                        margin: 5% 0%;
                    }
                }

                .Promises-Description {
                    text-align: center;
                    font-size: 14px;
                    font-family: "Sora", sans-serif;
                    color: #000000;
                    margin-block-end: 55px;

                    p {
                        margin-block-end: 5px;
                        height: 40px;
                    }

                    @include mobile {
                        font-size: 12px;
                        text-align: start;
                        margin-block-end: 35px;
                    }
                }
            }
        }
    }

    .About-Six {
        .Section-Six {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 1 / 2 fr);

            @include mobile {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 20px;
                margin-block-end: 60px;
            }

            .Diversity-Control {
                @include mobile {
                    width: inherit;
                }

                .Diversity {
                    font-size: 30px;
                    font-family: "Sora", sans-serif;
                    font-weight: 600;
                    color: #000000;
                    margin-block-end: 5rem;
                    line-height: 40px;
                    text-transform: inherit;
                    width: 85%;
                    margin-block-start: 0;

                    @include mobile {
                        font-size: 16px;
                        width: 100%;
                        text-align: start;
                        line-height: 22px;
                        margin-block-end: 2rem;
                    }
                }

                .Diversity-Description {
                    font-size: 14px;
                    font-family: "Sora", sans-serif;
                    color: #000000;
                    line-height: 22px;
                    text-align: start;
                    width: 76%;
                    margin-block-end: 5rem;

                    @include mobile {
                        font-size: 11px;
                        line-height: 22px;
                        width: 100%;
                        margin-block-end: 0rem;
                    }
                }
            }

            .Section6-GridImg {
                grid-row: 2 span;
                inset-inline-start: 8rem;

                @include mobile {
                    grid-row: 1/2;
                    inset-inline-start: 0rem;
                }

                .Img6 {
                    width: auto;
                    height: unset;

                    @include mobile {
                        width: 100%;
                    }
                }
            }

            .Section6-GridImg-1 {
                grid-row: 2 span;

                .Img7 {
                    width: auto;
                    height: unset;

                    @include mobile {
                        width: 100%;
                    }
                }
            }

            .Additional {
                inset-inline-start: 8rem;

                @include mobile {
                    width: inherit;
                    inset-inline-start: 0rem;
                }

                p {
                    font-size: 14px;
                    font-family: "Sora", sans-serif;
                    color: #000000;
                    line-height: 22px;
                    margin-block-end: 0;
                    text-align: start;

                    @include mobile {
                        font-size: 12px;
                        line-height: 22px;
                        width: 100%;
                        margin-block-end: 2rem;
                    }
                }
            }
        }
    }
}
