.Flowering_type {
    @include mobile {
        padding-inline: 16px;
    }

    .Flowering_type_grid {
        max-width: var(--content-wrapper-width);
        margin-inline: auto;

        .heading {
            font-size: 30px;
            font-weight: bold;
            text-align: center;
            margin-block-end: 15px;

            @include mobile {
                font-size: 18px;
            }
        }

        .heading_text {
            font-size: 16px;
            text-align: center;
            margin-block-end: 39px;

            @include mobile {
                font-size: 12px;
                line-height: 22px;
            }
        }

        .Flowering_type_main_grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-gap: 30px;

            @include mobile {
                display: flex;
                overflow-x: scroll;
                column-gap: 10px;
                padding-block-end: 20px;

                &::-webkit-scrollbar-thumb {
                    background-color: #739536;
                    height: 3px;
                }

                &::-webkit-scrollbar {
                    background: rgba(0, 0, 0, 0.08);
                    height: 3px;
                }
            }

            .grid {
                padding: 2.6rem 5.9rem;
                display: flex;
                flex-direction: column;

                @include mobile {
                    max-width: 169px;
                    padding: 2.5rem 2rem;
                }

                .Grid_text {
                    text-align: center;
                    font-size: 16px;
                    color: #000000;
                    text-transform: capitalize;
                    opacity: 1;
                    font-weight: bold;
                    max-width: 153px;
                    margin-inline: auto;

                    @include mobile {
                        font-size: 12px;
                        padding-block: 16px;
                    }
                }

                .Grid_image {
                    width: 100;
                    padding-block-end: 73%;

                    @include mobile {
                        min-width: 133px;
                        min-height: 91px;
                        padding-block-end: 67%;
                    }

                    img {
                        width: 100%;
                        position: absolute;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }

            .grid1 {
                background-color: #f2faf7;
                grid-column: 1/3;
                grid-row: 1/3;
                height: auto;
                padding: 0 0;
                padding-block-end: 3.4rem;

                @include mobile {
                    padding: 2.5rem 2rem;
                }

                .Grid_image {
                    padding-block-end: 67%;
                    width: 100%;

                    img {
                        position: absolute;
                    }
                }

                .Grid_text {
                    max-width: unset;
                    margin-block-start: 2.1rem;
                    display: flex;
                    justify-content: center;

                    @include mobile {
                        margin-block-start: 0;
                    }
                }
            }

            .grid2 {
                background-color: #f2faf7;
            }
        }
    }

    .flowering_type_images {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        max-width: var(--content-wrapper-width);
        margin-inline: auto;
        margin-block-start: 9.4rem;

        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .swiper-wrapper {
            align-items: center;
            column-gap: 5px;
        }

        @include mobile {
            display: flex;
            overflow-x: auto;
            column-gap: 35px;
            border-bottom: none;
            padding-block-end: 0;
            margin-block-start: 3.4rem;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .flowering_type_image_image {
            display: grid;
            place-content: center;
            border-bottom: 2px solid #dadedd;
            padding-block-end: 3rem;

            &:hover {
                border-bottom: 2px solid #739536;
            }

            @include mobile {
                min-width: 80px;
                border-bottom: none;
                padding-block-end: 0;
            }

            img {
                object-fit: contain;
                filter: grayscale(1);

                &:hover {
                    filter: none;
                }

                @include mobile {
                    width: 100%;
                    filter: none;
                }
            }
        }
    }

    .seedfiner_main_div {
        display: flex;
        max-width: var(--content-wrapper-width);
        margin-inline: auto;

        @include mobile {
            flex-direction: column;
        }
    }

    .Seedfinder_text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        .boldfont {
            font-weight: bold;
            font-size: 30px;
    
            @include mobile {
                font-size: 18px;
            }
        }
    }

    .Seedfinder_image {
        width: 100%;

        img {
            // max-width: 568px;
            width: 100%;
            max-height: 421px;
            height: auto;
            filter: brightness(50%);

            @include mobile {
                max-width: unset;
            }
        }

        margin: auto;
    }

    .normalfont {
        font-size: 14px;
        width: 100%;
        margin-block-start: 1.7rem;
        max-width: 447px;
        line-height: 22px;

        @include mobile {
            font-size: 12px;
        }
    }

    .find {
        position: absolute;
        z-index: 1;
        inset-inline-start: 50%;
        inset-block-start: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        font-weight: bold;
        border: 1px solid #ffffff;
        padding: 1.6rem 3.5rem;
        color: #ffffff;
    }

    .Homepage_seedfinder {
        margin-block-start: 8rem;

        @include mobile {
            margin-block-start: 35px;
        }
    }
}
