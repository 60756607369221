.Homepage-reviewSection {
    // max-width: var(--content-wrapper-width);
    width: 100%;
    // margin: 2rem auto;

    @include mobile {
        margin-block: 0 40px;
    }

    .heading {
        h2 {
            span {
                font-size: 30px;
            }

            @include mobile {
                margin-block-start: 0;
            }
        }
    }

    .Heading_section {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        justify-content: center;
        margin-block-end: 15px;

        @include mobile {
            padding-inline: 15px;
        }

        .heading {
            font-size: 30px;
            margin: 0;
            text-align: center;

            @include mobile {
                font-size: 18px;
            }
        }

        .heading-content {
            font-size: 14px;
            text-align: center;
            max-width: 626px;
            width: 100%;
            margin: auto;
            line-height: 22px;

            @include mobile {
                font-size: 12px;
            }
        }
    }

    .date-section {
        @include mobile {
            text-align: center;
        }
    }

    .swiper {
        @include desktop {
            padding: 0 0;
        }

        .pagebuilder-column-group {
            column-gap: 30px;

            @include mobile {
                row-gap: 30px;
            }
        }

        .swiper-button {
            &-next,
            &-prev {
                inset-block-start: 81%;
                position: absolute;
                border: none;
                width: 47px;
                height: 47px;
                background-color: #e6d93b;
                opacity: 1;
                z-index: 2;
                cursor: pointer;
                transform: rotate(-90deg);

                &::after {
                    content: "";
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    inset-inline-start: 18px;
                    box-sizing: border-box;
                    border-bottom: 2px solid #000000;
                    border-left: 2px solid #000000;
                }

                @include mobile {
                    inset-block-start: 36%;
                }
            }

            &-disabled {
                background-color: #fff;
                border: none;
                display: block;
            }

            &-next {
                inset-inline-end: 52%;

                &::after {
                    transform: rotate(-45deg);
                    inset-block-start: 15px;
                }

                @include mobile {
                    inset-inline-end: 15px;
                }
            }

            &-prev {
                inset-inline-start: 40%;

                &::after {
                    transform: rotate(-225deg);
                    inset-block-start: 20px;
                }

                @include mobile {
                    inset-inline-start: 15px;
                }
            }
        }

        p {
            line-height: 22px;

            @include mobile {
                margin-block-end: 0;
            }
        }

        .nameSection {
            display: flex;
            column-gap: 20px;

            @include mobile {
                flex-direction: column;
                align-items: center;
                row-gap: 22px;
            }

            .logo {
                width: 75px;
                height: 75px;

                img {
                    width: 100%;
                }
            }

            .name {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                row-gap: 10px;

                @include mobile {
                    max-width: 160px;
                }

                .star {
                    height: 16px;
                    margin-inline-end: auto;
                    display: flex;

                    @include mobile {
                        display: flex;
                        justify-content: center;
                        width: 79px;
                        margin: auto;
                    }

                    img {
                        height: 100%;
                    }
                }
            }
        }
    }
}
