@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CmsPage {
    min-height: 100vh;

    &_isBreadcrumbsHidden {
        margin-block-start: calc(var(--header-total-height) + 12px);

        @include mobile {
            margin-block-start: calc(var(--header-total-height) + 14px);
        }
    }

    &-Content {
        min-height: 100%;
        padding: 0;
        margin: 0;
        // overflow-x: hidden;
    }

    img {
        width: 100%;

        @include mobile {
            width: 100%;
        } 
    }
}
