.breeder-heading {
    .breeder-view {
        p {
            margin: 0 !important;/* stylelint-disable declaration-no-important */
            font-size: 16px;
            font-weight: 600;
            text-decoration-line: underline;
        }
    }

    .popular-header {
        margin-block-end: 39px;
    }

    display: flex !important; 
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center;
}

.popular-breeder-row {
    background-color: #F3F3F3;
    border-radius: 8px;
    padding: 30px 16px;
    margin-block-end: 32px;

    @include mobile {
        width: 176px;
        height: 147px;
    }

    .breeder-image {
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: auto;
        inset: 0;
        align-self: center;
    }
}

.pagebuilder-column-group {
    @include mobile {
        padding-inline-start: 12px;
        padding-inline-end: 12px;
    }

    .text-content-column {
        @include mobile {
            margin-inline-end: 0 !important;
            margin-inline-start: 0 !important;
        }
    }
}

.join-community-row {
    background-color: #F3F3F3;
    margin-block-start: 16px;
    border-radius: 8px;

    .join-community {
        .community-heading {
            @include mobile {
                font-size: 18px;
                padding-block-start: 18px;
                margin: 0;
            }

            padding-inline-start: 24px;
            padding-block-start: 26px;
            font-weight: 700;
            line-height: 32px;
            font-size: 28px;
            margin: 0;
        }

        .community-subheading {
            @include mobile {
                font-size: 14px;
                margin-block-end: 0 ;
                padding-block-end: 17px !important;
                margin: 0;
            }

            font-size: 16px;
            font-weight: 600;
            padding-inline-start: 24px;
            padding-block-end: 33px;
            padding-block-start: 5px;
            line-height: 24px;
            margin: 0;
        }

        .logo-icon {
            background-color: #D9D9D9;
            padding: 12px 16px;
            border-radius: 100%;
            position: absolute;
            transform: translate(0%,-50%);
            inset-block-start: 50%;
            inset-inline-end: 3%;
        }
    }

    .popularbreederwrapper {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    .popularbreederheading {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;

        @include mobile {
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
        }
    }

    .popularbreederlink {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; 
        text-decoration-line: underline;
    }

    .PopularBreederWrapper {
        background: #EEEEEE;
        padding-inline: 60px;

        @include mobile {
            padding-inline: 20px;
        }

        @include tablet {
            padding-inline: 20px;
        }
    }
}
