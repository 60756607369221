@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Overlay {
    &:not(.Overlay_isStatic) {
        @include mobile {
            padding-block-end: 0;
        }
    }
}

body {
    &:has(.Header_name_search) {
        .Overlay {
            &:not(.Overlay_isStatic) {
                @include mobile {
                    inset-block-start: 8rem;
                    height: calc(100% - 8rem);
                }
            }
        }
    }
}
