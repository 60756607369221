.termsandconditionsmain {
    max-width: var(--content-wrapper-width);
    margin: 3rem auto;

    @include mobile {
        margin: 2rem;
    }

    .termsandconditions_overview { 
        margin: 0;

        .termsandconditionsheading_normal_text {
            font-weight: 600;
        }

        .termsandconditions_normalfont_list {
            li {
                list-style-type: disc;
            }
        }
        
        .termsandconditionsheading_price {
            text-transform: none;
        }
    }
}
