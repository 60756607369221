@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.FieldForm-Body.CheckoutAddress {
    opacity: 0.4;
    pointer-events: none;
}
