.mainclassbanner {
    @include mobile {
        margin-block-end: 48px !important;/* stylelint-disable declaration-no-important */
    }

    min-height: 520px !important;
    margin: 0 !important;
    margin-block-end: 40px !important;

    .bannerheading {
        @include mobile {
            margin-block-start: 168px;
            font-size: 28px;
            font-weight: 700;
            line-height: 32px; 
        }

        color: var(--shade-black, #000);
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
        margin: 0 !important;
        margin-block-start: 211px;
        margin-block-end: 158px;
    }

    .button-container {
        display: flex;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        border-radius: 8px;
        position: relative;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0px;
        text-transform: uppercase;
        inset-block-start: 150px;
        
        @include tablet {
            padding: 12px 16px;
            justify-content: center;
            align-items: center;
            gap: 16px;
            border-radius: 8px;
            position: relative;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0px;
            text-transform: uppercase;                      
        }

        @include mobile {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            grid-gap: 10px;
            padding: 12px 16px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            position: relative;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0px;
            text-transform: uppercase;
            inset-block-end: 0px;
        }

        @include mobile {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            grid-gap: 10px;
            padding: 12px 16px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            position: relative;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0px;
            text-transform: uppercase;
        }

        .ctadiscription {
            .pagebuilder-button-primary {
                span {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                }
                
                &:hover {
                    background-color: var(--primary-dark-color);
                    border-radius: 8px;
                }

                color: white;
                display: flex;
                padding: 12px 16px;
                justify-content: center;
                align-items: center;
                gap: 16px;
                border-radius: 8px;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; 
                letter-spacing: 0px;
                text-transform: uppercase;

                @include mobile {
                    font-size: 12px !important;
                    width: 100%;
                    height: 100%;
                    padding: 16px 12px 16px 12px;
                }
            }
        }
    }
}
