@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable number-max-precision */
/* stylelint-disable no-missing-end-of-source-newline */

.RewardProgram {
    @include desktop {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 1rem;
        padding-block-end: 2rem;
    }

    margin-block-start: 20px;


    &-EnteredLabel {
        td {
            min-width: unset;
        }
    }

    &-rewardPoint {
        display: flex;
        font-size: 14px;
        align-items: center;

        > span {
            margin-inline-end: 0.5rem;
        }

        @include mobile {
            margin-block-start: 16px;
            font-size: 12px;
            margin-block-end: 16px;
        }
    }

    &-exchangeRates {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: 1rem;
            padding-block-end: 2rem;
        }

        margin-block-start: 2rem;
    }

    &-balanceHistoryDetails {
        display: block;
        padding: 21px 30px;

        @include mobile {
            padding: 15px;
        }

        margin-block-start: 26px;
        background-color: #F5F5F5;
        padding-block-end: 0.1rem;
    }

    &-pointBalanceInformation {
        font-size: 1.8rem;
        font-weight: 500;
        color: #000000;
        
        @include mobile {
            font-size: 14px;
        }
    }

    &-balanceHistory {
        margin-block-start: 3rem;
        font-size: 20px;
        font-weight: 600;
        color: #000000;

        @include mobile {
            font-size: 16px;
            margin-block-end: 26px;
        }

        @include desktop {
            margin-block-end: 26px;
        }
    }

    &-SubscribeForm {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 1fr;
            font-size: 14px;
        }

        color: #6F6F6F;

        .Field [type='checkbox']+.input-control,
        .Field [type='radio']+.input-control {
            border: 1px solid #959499;
            border-radius: 16%;
        }

        @include mobile {
            font-size: 12px;
            margin-block-end: 100px;
        }
    }

    .Field [type='checkbox']+.input-control,
    .Field [type='radio']+.input-control {
        border: 1px solid #959499;
    }

    &-SaveButton {
        @include desktop {
            margin-block-start: 26px;
            width: 189px;
        }

        @include mobile {
            margin: 0;
        }
    }

    &-balanceHistoryTable {
        width: 100%;
        color: #000000;

        th {
            background-color: unset;
            border-radius: unset;
            font-weight: 600;

            @include mobile {
                font-weight: 500;
                padding-block: 10px;
                padding-inline-start: unset;
                padding-inline-end: unset;
            }

            @include desktop {
                padding: 20px;
            }
        }

        td {
            @include mobile {
                text-align: end;
            }

            @include desktop {
                border-top: 1px solid #AFAFAF;
                padding: 20px;
            }
        }

        font-size: 14px;
    }

    &-rewardPointTitle {
        color: #000000;

        @include mobile {
            font-size: 12px;
        }
    }

    &-emailNotification {
        margin-block-start: 4rem;
    }

    &-HeadingDate {
        @include mobile {
            width: 200%;
            display: grid;
            grid-template-columns: 1.2fr 5.2fr 2.5fr;
            grid-gap: 10px;
        }

        .RewardBorder {
            @include mobile {
                border-top: 0.5px solid #C5CBD5;
                margin-block-start: 10px;
            }
        }
    }

    &-DateTitle {
        font-weight: 500;
        margin-block-end: 10px;
    }

    &-Date {
        text-align: end;
    }

    &-Border {
        @include mobile {
            border-bottom: 0.5px solid #C5CBD5;
            margin-block: 10px 26px;
            width: 197%;
        }
    }

    &-emailNotificationTitle {
        font-size: 20px;
        font-weight: 600;
        color: #000000;

        @include mobile {
            font-size: 16px;
            margin-block-end: 26px;
        }

        @include desktop {
            margin-block-end: 26px;
        }
    }

    &-rewardPointContent {
        margin-inline-start: 5px;

        @include mobile {
            font-size: 12px;
        }
    }

    @include mobile {
        &-Buttons {
            position: fixed;
            inset-block-end: 0;
            inset-inline: 0;
            background-color: #FFFFFF;
            padding: 16px;
            z-index: 1;
        }
    }

    &-earnPointTitle {
        text-align: end;
        margin-block-start: 3rem;
        color: #739536;
        text-decoration: underline;
        cursor: pointer;

        a {
            font-weight: 500;
        }

        @include desktop {
            font-size: 14px;
        }
    }

    &-balanceHistoryTitle {
        display: grid;
        width: 100%;
        grid-template-columns: 4fr 4fr;
    }

    &-Heading-Border {
        @include desktop {
            border-bottom: 0.5px solid #8D8D8D;
        }

        @include mobile {
            border-bottom: 0.5px solid #C5CBD5;
        }

        opacity: 100%;
    }
}


