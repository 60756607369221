@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';

/**
 * Amasty Extra Fee compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.Fee {
    &-Name {
        margin-block-start: 16px;
        font-size: 16px;
        font-weight: 600;

        &_Mandatory::after {
            content: "*";
            display: inline-block;
            color: var(--primary-error-color, red);
        }
    }

    &-Description {
        margin-block-start: 8px;
        font-size: 13px;
    }

    .Field {
        margin-block-start: 0;

        .FieldSelect {
            &-Select {
                border: 1px solid var(--input-border-color);
                margin-block-start: 1rem;
                padding-inline-end: 3rem;
                text-overflow: ellipsis;
            }

            .ChevronIcon {
                inset-block-start: 2.3rem;
            }
        }
    }

    label {
        font-size: 16px;

        @include mobile {
            font-size: 14px;
        }
    }
}
