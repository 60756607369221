@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.StoreSwitcher {
    border-block-end: unset;
    margin-inline-start: unset;
    display: inline-flex;
    gap: 2px;

    &-Title {
        line-height: 20px;
    }

    &-storeLabel {
        margin: auto;
    }

    img {
        margin-block-start: 2px;
    }
}
