@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.PasswordChangePage {
    .Field-Wrapper {
        margin-block-end: 4rem;
    }
}
