.ImageCardWrapper {
    .ImageCardButton {
        display: flex;
        align-self: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.32px;/* stylelint-disable-line number-max-precision */
        text-transform: uppercase;
        position: absolute;
        margin-block-end: 32px;
        inset-block-end: 0;
        z-index: 99;

        @include mobile {
            margin-block-end: 12px;
        }

        .pagebuilder-button-primary {
            background-color: #FFF;
            color: #000;
            border: #FFF;
            padding: 12px 16px 12px 16px;
            border-radius: 8px;
            cursor: pointer;
        }

        .pagebuilder-button-primary:not([disabled]):hover {
            background-color: #FFF;
            color: #000;
            border: #fff;
        }
    }
    
    .ImageCardImage {
        border-radius: 8px;
        overflow: hidden;
        position: relative;

        img {
            height: 400px;
        }
    }
    
    .ImageCardImage::before {
        content: "";
        position: absolute!important;/* stylelint-disable declaration-no-important */
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to top, #000 0%, rgba(217, 217, 217, 0.34) 75%);
        z-index: 99;
    }    
}
