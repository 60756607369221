.Cannabisseeds {
    margin-inline: auto;

    .Cannabisseeds-Heading {
        .Heading {
            font-size: 30px;
            font-family: "Sora", sans-serif;
            font-weight: 600;
            color: #000000;
            margin-block-end: 3%;

            @media only screen and (max-width: 810px) {
                font-size: 16px;
                margin-block: 22px;
            }
        }
    }
    
    .Cannabis-Grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 30px;

        @media only screen and (max-width: 810px) {
            grid-template-columns: repeat(2,1fr);
            grid-gap: 5px;
        }

        .Cannabis-Img {
            position: relative;

            .Cannabis {
                width: 100%;
            }

            .Cannabis-Lable {
                position: absolute;
                inset-block-end: 30px;
                width: 100%;
                text-align: center;

                @include mobile {
                    inset-block-end: 15px;
                }

                .Lable {
                    font-size: 18px;
                    color: #FFFFFF;
                    margin-block-end: 0;
                    line-height: 28px;

                    @media only screen and (max-width: 810px) {
                        font-size: 14px;
                        margin-block-end: 0;
                        line-height: 21px;
                    }
                }
            }
        }
    }
}
