@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.RingFencedPopup { 
    .Popup-Content {
        padding: 50px;
        max-width: 555px;

        .RainFenced {
            &-Wrapper {
                text-align: center;
            }
        }
    }

    &-HeaderContent {
        h3 {
            margin-block-start: 0;
            font-size: 25px;
            font-weight: 500;
        }
    }

    &-Message {
        color: var(--color-gray);
        margin-block-end: 3rem;
    }

    &-Actions {
        display: grid;
        row-gap: 3rem;
    }

    &-orText {
        display: flex;
        width: 100%;
        gap: 5px;
        color: var(--color-gray);
        font-weight: 500;
        font-size: 16px;
        align-items: center;

        @include mobile {
            font-size: 10px;
        }

        &::after,
        &::before {
            border-bottom: 0 solid #e8e8e8;
            background-color: #e8e8e8;
            content: "";
            display: inline-block;
            height: 1px;
            position: relative;
            vertical-align: middle;
            width: 100%;
        }
    }

    &-Login,
    &-Continue {
        button {
            width: 100%;
            font-weight: 500;
        }

        p {
            margin-block-end: 3rem;
        }
    }

    &-Continue {
        button {
            border: 1px solid var(--color-black);
            color: var(--color-black);
        }
    }
}
