/* stylelint-disable declaration-no-important */

.Main_Container {
    .Seed_Storage {
        display: grid;
        grid-template-columns: 1fr 2fr;
        max-width: var(--content-wrapper-width);
        width: 100%;
        gap: 30px;
        margin: 3% auto;
        padding-inline: 30px;

        .Seed_Image {
            width: 382px;
            height: 388px;

            @include mobile {
                padding-block: 30px;
            }
        }

        .Seed_Image img {
            width: 100%;
            height: 100%;
        }

        .Seed_content {
            .Seed_header {
                font-size: 30px;
                letter-spacing: 0px;
                line-height: 1;
                color: #000000;

                @include mobile {
                    font-size: 16px;
                }
            }

            .para_list {
                font-size: 14px;
                letter-spacing: 0px;
                width: 81%;
                line-height: 1.7;
                margin-inline-start: 20px;

                li {
                    list-style: inherit;
                    margin-block-start: 20px;
                    max-width: 100%;

                    &::before {
                        display: none;
                    }

                    @include mobile {
                        color: #000000;
                        font-size: 12px;
                    }
                }

                ::marker {
                    color: #739536;
                    font-size: 32px;
                }
            }
        }
    }
}

html #html-body {
    .CmsPage {
        .vertical.tab-align-left {
            display: grid;
            grid-template-columns: 1fr 2fr;
            max-width: var(--content-wrapper-width);
            margin-inline-start: auto;
            gap: 8rem;
    
            @include mobile {
                flex-direction: column;
                padding: 20px;
                gap: 0;
                margin-block: 0;
            }
    
            .tabs-navigation {
                // max-width: 400px;
                width: 100%;
    
                @include mobile {
                    display: grid;
                    grid-template-columns: repeat(4, auto);
                    gap: 10px;
                    overflow-x: auto;
    
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
    
    
                .tab-header {
                    width: 100%;
                    border: none;
                    border-bottom: 2px solid #959595;
                    background: #f2faf7;
                    padding: 27px 0;
    
                    @include mobile {
                        padding: 15px;
                    }
    
                    .tab-title {
                        padding: 0;
    
                        span {
                            color: #959595;
                            padding: 0;
                            font-size: 30px;
                            white-space: nowrap;
    
                            @include mobile {
                                font-size: 12px;
                            }
                        }
                    }
    
                    &.ui-tabs-active {
                        border-bottom: 2px solid #739536;
                        background: #f2faf7;
    
                        .tab-title {
                            span {
                                color: #739536;
                            }
                        }
                    }
    
                    &:last-child {
                        border-right: none;
                    }
                }
            }
    
            .tabs-content {
                border: none;
    
                .Feature-Tab {
                    padding: 0;
    
                    .Content-Heading {
                        padding: 0;
                    }

                    p {
                        text-align: justify;
                    }
                }
            }
        }

        .seedContent-mobile {
            @include desktop {
                display: none;
            }
        }
    }
}


@media only screen and (max-width: 760px) {
    .CmsPage {
        .CmsPage-Wrapper {
            .CmsPage-Content {
                .seedcontent-desktop {
                    display: none !important;
                }
            }
        }
    }
 

    .Main_Container {
        .Seed_Storage {
            display: block;
            width: 100%;
            margin: auto;
            gap: 12%;

            .Seed_Image {
                width: 100%;
            }
        }
    }

    .seedsHeading {
        .ExpandableContent-Heading {
            color: #000000;
            font-size: 12px;
        }

        p {
            color: #000000;
            font-size: 12px;
        }
    }
}
