@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable rule-empty-line-before */
/* stylelint-disable no-missing-end-of-source-newline */

:root {
    --field-label-color: var(--color-gray);
    --checkbox-border-color-active: #000;
    --imported_checkboxes_border_radius: 2px;
}

.Field {
    @include mobile {
        margin-block-start: 24px;
    }

    &-Label {
        font-size: 1.4rem;
        transform: scale(1);
        transform-origin: left;
        transition: all 0.3s ease;
    }

    &-Error {
        &Message {
            text-align: start;
            font-size: 11px;

            @include smallmobile {
                font-size: 9px;
            }
        }
    }

    textarea {
        margin-block-start: 16px;
    }

    .Field-Wrapper_type_textarea {
        .Field {
            &:focus-within {
                .Field-LabelContainer {
                    inset-block-end: 5rem;

                    .Field-Label {
                        transform: scale(0.8);

                        @include mobile {
                            transform: scale(0.9);
                        }
                    }
                }
            }
        }

        .Field_type_textarea {
            inset-block-start: 16px;

            .Field-LabelContainer {
                inset-block-end: 0;
            }
        }
    }

    &_hasError {
        input:not([type="number"]),
        textarea,
        select {
            border: none;
            border-bottom: 1px solid var(--primary-error-color);
        }
    }

    &_type {
        &_text,
        &_textarea,
        &_email,
        &_password,
        &_date,
        &_time,
        &_datetime,
        &_datetime-local,
        &_file,
        &_select {
            &.Field_isValid {
                input,
                textarea,
                select {
                    &:placeholder-shown {
                        border: none;
                        border-bottom: 1px solid #959499;
                    }

                    border: none;
                    border-bottom: 1px solid var(--primary-success-color);
                }
            }
        }

        &_checkbox,
        &_radio {
            label {
                &:first-of-type {
                    align-items: center;
                }
            }
        }

        &_date {
            input::-webkit-date-and-time-value {
                text-align: start;
            }
        }

        &_file {
            &.Field {
                &_isValid {
                    input {
                        & + label {
                            word-break: break-word;
                            text-align: start;
                            border-color: var(--primary-success-color);
                        }
                    }
                }

                &_hasError {
                    input {
                        & + label {
                            word-break: break-word;
                            text-align: center;
                            border-color: var(--primary-error-color);
                        }
                    }
                }
            }
        }
    }

    [type="checkbox"] {
        border-radius: var(--imported_checkboxes_border_radius);

        &:checked {
            + .input-control {
                background-color: #000;
                border-color: var(--checkbox-border-color-active);

                &::after {
                    --checkmark-color: var(--color-white);
                }
            }
        }
    }

    [type="radio"] {
        + .input-control {
            border-radius: 50%;

            &::after {
                --checkmark-color: transparent;

                background: var(--checkmark-color);
                box-shadow: none;
                height: 8px;
                border-radius: 50%;
                inset-inline-start: 3px;
                inset-block-start: 3px;
                width: 8px;

                @include mobile {
                    inset-inline-start: 4px;
                    inset-block-start: 4px;
                    height: 9px;
                    width: 9px;
                }
            }
        }

        &:checked {
            + .input-control {
                &::after {
                    --checkmark-color: var(--color-black);
                }
            }
        }
    }

    [type="file"] {
        cursor: pointer;
        height: fit-content;
        opacity: 0;
        max-width: 100%;
        z-index: 1;

        + label {
            border-block-end: 1px solid var(--input-border-color);
            border-inline: none;
            border-block-start: none;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            height: 43px;
            position: absolute;
            inset-block-start: 0;
            width: 100%;

            p {
                font-size: 14px;
                margin-block-end: 0;
                width: 100%;
            }

            span {
                color: var(--primary-base-color);
                font-size: 18px;
                font-weight: 700;
                text-transform: uppercase;
            }

            + p {
                margin-block-start: 5px;
                margin-block-end: 0;
                width: 100%;
            }
        }
    }

    input::placeholder,
    textarea::placeholder {
        color: var(--input-color);
        opacity: 0;
    }

    &-LabelContainer {
        display: flex;
        align-items: center;
        position: absolute;
        inset-block: 0 0rem;
        inset-inline-start: 0;
        z-index: 1;
        color: var(--field-label-color);
        transition: all 0.1s ease;
        pointer-events: none;

        .ChevronIcon {
            width: 23px;
        }
    }

    &:focus-within {
        .Field-LabelContainer {
            inset-block: 0 3rem;

            .Field-Label {
                transform: scale(0.8);

                @include mobile {
                    transform: scale(0.9);
                }
            }
        }
    }

    &-Wrapper {
        &.isPreFilled {
            .Field {
                &.Field {
                    .Field-LabelContainer {
                        inset-block: 0 3rem;

                        .Field-Label {
                            transform: scale(0.8);

                            @include mobile {
                                transform: scale(0.9);
                            }
                        }
                    }
                }
            }
        }

        &.isPreFilled_type_textarea {
            .Field {
                &.Field {
                    .Field-LabelContainer {
                        inset-block-start: -5px;
                        padding-block-end: 10px;
                        height: fit-content;

                        .Field-Label {
                            transform: scale(0.8);

                            @include mobile {
                                transform: scale(0.9);
                            }
                        }
                    }
                }
            }
        }
    }

   
    &.Field_isValid,
    &.Field_hasError,
    &.Field_type_date {
        .Field-LabelContainer {
            inset-block: 0 3rem;
            
            .Field-Label {
                transform: scale(0.8);

                @include mobile {
                    transform: scale(0.9);
                }
            }
        }
    }

    &.Field_hasError {
        .Field-LabelContainer {
            inset-block: 0 3rem;

            .Field-Label {
                transform: scale(0.8);

                @include mobile {
                    transform: scale(0.9);
                }
            }
        }
    }

    &.Field_type_select {
        .Field-LabelContainer {
            inset-block: 0 3rem;

            .Field-Label {
                transform: scale(1);
            }
        }
    }

    &-ErrorMessages {
        position: absolute;
        /* stylelint-disable-next-line csstools/use-logical */
        display: flex;
        align-items: center;
        column-gap: 5px;
    }

    &-Wrapper_type_textarea {
        .Field {
            &:focus-within {
                .Field-LabelContainer {
                    inset-block-end: 5rem;

                    .Field-Label {
                        transform: scale(0.8);

                        @include mobile {
                            transform: scale(0.9);
                        }
                    }
                }
            }
        }

        .Field_type_textarea {
            .Field-LabelContainer {
                inset-block-end: 0;
            }
        }
    }
}

.Field:has(input:-webkit-autofill) {
    .Field-LabelContainer {
        inset-block: 0 3rem;

        .Field-Label {
            transform: scale(0.8);

            @include mobile {
                transform: scale(0.9);
            }
        }
    }
}

