@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.NoMatch {
    opacity: 0;
    animation: appear 100ms 300ms forwards;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        min-height: calc(100vh - var(--header-height) - var(--breadcrumbs-height));
        margin-block-end: 0;
        margin-block-start: 0;
    }

    h1 {
        font-size: 144px;
        font-weight: 400;
        color: var(--primary-base-color);
        line-height: 1;

        @include mobile {
            font-size: 100px;
        }
    }

    &-Button {
        margin-block-start: 24px;
        
        @include desktop {
            width: 200px;
        }

        @include mobile {
            margin-block-start: 28px;
        }
    }

    &-Subtitle {
        font-size: 36px;
        font-weight: 700;
        margin: 0;
        line-height: 48px;

        @include mobile {
            font-size: 20px;
            line-height: 32px;
            margin-block-end: 15px;
        }
    }

    &-Wrapper {
        text-align: center;
    }

    &-NewLinks {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5rem;

        @include mobile {
            flex-direction: column;
            gap: 0rem;
        }
    }
}
