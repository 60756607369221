
.PrimaryBannerGridWrapper {
    display: flex;
    flex-direction: row;
    gap: 24px;
    background: #EEE;
    position: relative; 

    @include mobile {
        flex-direction: column;
        gap: 16px;
    }

    .PrimaryBannerImageGridOne {
        width: 866px;
        height: 520px;
        border-radius: 8px;

        @include mobile {
            width: 100%;
            height: 360px;
            margin-inline-end: 12px;
            margin-inline-start: 12px;
        }

        @include tablet {
            width: 100%;
            height: 460px;
        }
    }

    .PrimaryBannerImageGridTwo {
        width: 420px;
        height: 258px;
        border-radius: 8px;
        padding-block-end: 24px;

        @include mobile {
            width: 100%;
            height: 215px;
            padding-block-end: 16px;
            margin-inline-end: 12px;
            margin-inline-start: 12px;
        }

        @include tablet {
            width: 100%;
            height: 230px;
            padding-block-end: 16px;
        }
    }

    .PrimaryBannerImageGridThree {
        width: 420px;
        height: 258px;
        border-radius: 8px;

        @include mobile {
            width: 100%;
            height: 215px;
            margin-inline-end: 12px;
            margin-inline-start: 12px;
        }

        @include tablet {
            width: 100%;
            height: 230px;
        }
    }

    .PrimaryBannerGridWrapperChild {
        display: flex;
        flex-direction: column;
    }
}

.buttonthreebanners {
    @include mobile {
        position: absolute;
        inset-block-end: 440px;
        margin-inline-start: 0px;
    }
    
    
    position: absolute;
    padding: 12px 16px;
    inset-block-end: 32px;
    margin-inline-start: 32px; 
}
