@font-face {
    font-family: 'Sora';
    src: url(/style/fonts/Sora-Bold.woff2) format('woff2'),url(/style/fonts/Sora-Bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sora';
    src: url(/style/fonts/Sora-ExtraBold.woff2) format('woff2'),url(/style/fonts/Sora-ExtraBold.woff) format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sora';
    src: url(/style/fonts/Sora-Light.woff2) format('woff2'),url(/style/fonts/Sora-Light.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sora';
    src: url(/style/fonts/Sora-Medium.woff2) format('woff2'),url(/style/fonts/Sora-Medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sora';
    src: url(/style/fonts/Sora-Regular.woff2) format('woff2'),url(/style/fonts/Sora-Regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sora';
    src: url(/style/fonts/Sora-SemiBold.woff2) format('woff2'),url(/style/fonts/Sora-SemiBold.woff) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sora';
    src: url(/style/fonts/Sora-Thin.woff2) format('woff2'),url(/style/fonts/Sora-Thin.woff) format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sora';
    src: url(/style/fonts/Sora-ExtraLight.woff2) format('woff2'),url(/style/fonts/Sora-ExtraLight.woff) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
$font-Sora: 'Sora';

@font-face {
    font-family: 'Inter';
    src: url(/style/fonts/Inter-ExtraLight.woff2) format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/style/fonts/Inter-Thin.woff2) format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/style/fonts/Inter-Light.woff2) format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/style/fonts/Inter-Regular.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/style/fonts/Inter-Medium.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/style/fonts/Inter-SemiBold.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url(/style/fonts/Inter-Bold.woff2) format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

$font-Inter: 'Inter';

body {
    font-family: $font-Inter;
    font-display: swap;
}
