@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';

/**
 * Amasty Extra Fee compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.Fees {
    margin-block-end: 0;
    padding: 0 24px;

    @include mobile { 
        padding : 0 16px;
    }

    .Loader {
        display: none;
    }
}
