@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.SearchField {
    @include desktop {
        width: 90%;
        max-width: 600px;
        grid-column: 2 / 3;
        margin: unset;
        display: block;
    }

    @include mobile {
        max-width: unset;
        width: unset;
        margin-block-start: -9px;
        padding: 10px;
    }

    &-SearchIcon {
        position: absolute;
        inset-inline-start: 0px;
        height: 50px;
        inset-block-start: calc(30% - 15px);

        @include desktop {
            width: 58px;
        }

        @include mobile {
            height: unset;
            inset-block-start: calc(30% - 21px);
        }

        cursor: pointer;
    }

    &-CloseIcon {
        inset-inline-end: 6px;

        svg {
            fill: var(--color-black);
            cursor: pointer;
        }

        @include mobile {
            display: none;
        }

        // @include desktop {
        //     display: none;
        // }
    }

    &-Input {
        width: 100%;
        height: 48px;
        background: #FAFAFA;
        border-bottom: unset;
        padding: 16px 30px 16px 50px;
        border: 1px solid #B2B1B0;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 600;
        color: #000000;

        &::placeholder {
            font-weight: 400;
        }

        @include mobile {
            display: none;
        }
    }

    &_isActive,&-isVisible {
        min-height: 100%;
    }

    // input:focus {
    //     border-bottom: unset;
    // }

    &_isActive {
        @include mobile {
            display: block;

            .SearchField-SearchIcon { 
                display: none;
            }

            input { 
                display: block;
            }

            .SearchField-SearchInnerWrapper {
                &::before {
                    content: "";
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    background-image: url("src/util/images/Search.svg");
                    background-repeat: no-repeat;
                    z-index: 1;
                    inset-block-start: 15px;

                    @include mobile {
                        inset-inline-start: 10px;
                        inset-block-start: 14px;
                    }
                }
            }
        }

        @include desktop {
            input:focus { 
                border: 2px solid #000000;
                background-color: #EAF5E7;
            }
            
            .SearchField-SearchInnerWrapper {
                &:has(input#search-field:focus) {
                    .SearchField-CloseIcon {
                        display: block;
                    }
                }
            }
        }
    }
}
